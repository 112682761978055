import React from "react";

import { ButtonContainer, Container, Header } from "./styles";
import { Button } from "../../Buttons";
import { Loader } from "../../Loaders";
import { Modal } from "../Modal";

export const ConfirmDeleteModal = ({
  isLoading,
  onDelete,
  id,
  onClose,
  heading,
}) => {
  return (
    <Modal maxWidth="444px">
      <Container>
        <Header>Вы уверенны, что хотите удалить {heading}?</Header>
        <ButtonContainer>
          <Button width={125} height={46} onClick={() => onDelete(id)}>
            {isLoading ? <Loader /> : "Удалить"}
          </Button>
          <Button width={125} height={46} outlined onClick={onClose}>
            Отмена
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
