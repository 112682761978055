import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";

import { useAuth } from "../../hooks/useAuth";

import {
  Container,
  LoginContainer,
  ImageContainer,
  LogoMantrix,
  InnerContainer,
  WelcomeText,
  Entrance,
  Text,
  LoginInput,
  LoginButton,
  ForgotPasswordText,
  ErrorMessage,
} from "./styles";
import { baseUrl, getSubdomainName } from "../../api";
import { Loader } from "../../components/UI/Loaders";

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();

  const [loginError, setLoginError] = useState(false);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        login: "",
        password: "",
      },
      validationSchema: yup.object({
        login: yup.string().required("Обязательное поле"),
        password: yup
          .string()
          .min(6, "Пароль должен содержать не меньше 6 символов")
          .required("Обязательное поле"),
      }),
      onSubmit: (value) => {
        loginMutation.mutate(value);
      },
    });

  const fromPage = location.state?.from?.pathname || "/";

  const loginMutation = useMutation(
    "auth",
    ({ login, password }) =>
      axios.post(
        `${baseUrl}/api/auth`,
        {},
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: "Basic",
          },
          auth: {
            username: login,
            password,
          },
        }
      ),
    {
      onSuccess: (data) => {
        signIn(data.data.data.token, () =>
          navigate(fromPage, { replace: true })
        );
      },
      onError: () => setLoginError(true),
    }
  );

  return (
    <Container>
      <LoginContainer>
        <InnerContainer>
          <LogoMantrix />
          <div>
            <WelcomeText>Добро пожаловать</WelcomeText>
            <Entrance>
              <span>Вход</span>
            </Entrance>
            <form onSubmit={handleSubmit}>
              <Text>
                Email
                {touched.login && errors.login && <span>{errors.login}</span>}
              </Text>
              <LoginInput
                type="email"
                name="login"
                value={values.login}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Text>
                Password
                {touched.password && errors.password && (
                  <span>{errors.password}</span>
                )}
              </Text>
              <LoginInput
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <ForgotPasswordText>
                <a href="/">Забыли пароль?</a>
              </ForgotPasswordText>

              <LoginButton type="accept" disabled={loginMutation.isLoading}>
                {loginMutation.isLoading ? <Loader /> : "Войти"}
              </LoginButton>
            </form>
          </div>

          {loginError && (
            <ErrorMessage>
              Ошибка авторизации! Неверный логин или пароль
            </ErrorMessage>
          )}
        </InnerContainer>
      </LoginContainer>

      <ImageContainer>
        <img src="/illustration.png" alt="background image" />
      </ImageContainer>
    </Container>
  );
};
