import styled from "styled-components";
import Select from "react-select";

import { Button } from "../../Buttons";
import { Modal } from "../Modal";

export const StyledModal = styled(Modal)`
  overflow-y: visible;
`;

export const SelectElement = styled(Select)`
  .react-select-container {
    // custom styles
    //padding: 0;
  }

  .react-select__menu {
    z-index: 2;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  .react-select__control {
    //background-color: transparent;
    //border: none;
    //box-shadow: none;
    //align-items: start;
    //min-height: 26px;
  }

  .react-select__input-container {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  .react-select__value-container {
    padding-left: 22px;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  .react-select__indicators {
  }
`;

export const Header = styled.h2`
  align-self: center;
`;

export const Form = styled.form`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.img`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  align-self: flex-end;
  margin: 12px 12px 0 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #7a8fa3;
`;

export const LabelMultiple = styled.label`
  display: flex;
  margin-bottom: 15px;
  color: #7a8fa3;

  > div {
    margin-left: 20px;

    > label {
      margin-right: 22px;
    }
  }
`;

export const Input = styled.input`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  outline: none;
  padding-left: 22px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
`;

export const Textarea = styled.textarea`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 80px;
  font-size: 14px;
  outline: none;
  padding: 5px 22px;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  letter-spacing: 0.0041em;
`;
