import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import {
  Breadcrumbs,
  Commentary,
  Container,
  Details,
  Main,
  MainInner,
  Name,
} from "./styles";
import { DocumentCard } from "./DocumentCard";
import { Author, Avatar } from "../../pages/CatalogPage/styles";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl, getSubdomainName } from "../../api";
import { ConfirmDeleteModal } from "../UI/Modals/ConfirmDeleteModal";
import { DocumentModal } from "../UI/Modals/DocumentModal";
import { DirectoriesAddButton } from "../UI/DirectoriesAddButton";
import { DirectoriesLoader } from "../../pages/DirectoryPage/styles";

export const Documents = () => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  const [documentToDeleteId, setDocumentToDeleteId] = useState(null);
  const [documentToEdit, setDocumentToEdit] = useState(null);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isDocumentModalShown, setIsDocumentModalShown] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery("getAllDocuments", async () =>
    axios.get(`${baseUrl}/catalog/docs`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const createMutation = useMutation(
    "addDocument",
    async ({ name, comment }) => {
      await axios.post(
        `${baseUrl}/catalog/docs`,
        {
          name,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllDocuments");
        setIsDocumentModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editDocument",
    async ({ name, comment }) => {
      await axios.put(
        `${baseUrl}/catalog/docs/${documentToEdit.id}`,
        {
          name,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsDocumentModalShown(false);
        queryClient.invalidateQueries("getAllDocuments");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteDocument",
    async (id) => {
      await axios.delete(`${baseUrl}/catalog/docs/${id}`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllDocuments");
      },
    }
  );

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
    setIsDeleteModalShown(false);
  };

  if (isLoading) return <DirectoriesLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <Container>
      <Breadcrumbs>
        <div>
          <span>Справочники</span>
          <img
            style={{ margin: "0 10px" }}
            src="/breadCrumbArrow.svg"
            alt="arrow"
          />
        </div>
        <div>
          <span>Документы</span>
        </div>
      </Breadcrumbs>

      <Main
        isItemSelected={!!selectedDocument}
        onClick={() => setSelectedDocument(false)}
      >
        <DirectoriesAddButton
          onClick={() => {
            setDocumentToEdit(null);
            setIsDocumentModalShown(true);
          }}
        >
          документ
        </DirectoriesAddButton>

        <MainInner isItemSelected={!!selectedDocument}>
          {response?.data?.data?.docs.map((document, index) => (
            <DocumentCard
              isSelected={selectedDocument.id === document.id}
              key={document.id}
              document={document}
              index={index + 1}
              onEdit={(e) => {
                e.stopPropagation();
                setDocumentToEdit(document);
                setIsDocumentModalShown(true);
              }}
              onDelete={(e) => {
                e.stopPropagation();
                setDocumentToDeleteId(document.id);
                setIsDeleteModalShown(true);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDocument(document);
              }}
            />
          ))}
        </MainInner>
      </Main>

      {selectedDocument && (
        <Details>
          <div>
            <Name>
              <span>Наименование программы</span>
              <p>{selectedDocument ? selectedDocument.name : " "}</p>
            </Name>

            <Author>
              <span>Автор</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>{selectedDocument ? selectedDocument?.author?.name : " "}</p>
                {selectedDocument?.author?.photo && (
                  <Avatar src={selectedDocument?.author?.photo} alt="pic" />
                )}
              </div>
            </Author>
          </div>

          <Commentary>
            <span>Комментарий</span>
            <p>{selectedDocument ? selectedDocument.comment : " "}</p>
          </Commentary>
        </Details>
      )}

      {isDocumentModalShown && (
        <DocumentModal
          isEdit={!!documentToEdit}
          document={documentToEdit}
          isLoading={createMutation.isLoading || editMutation.isLoading}
          onCreate={handleCreate}
          onEdit={handleEdit}
          onClose={() => setIsDocumentModalShown(false)}
        />
      )}

      {isDeleteModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteMutation.isLoading}
          id={documentToDeleteId}
          onDelete={handleDelete}
          onClose={() => setIsDeleteModalShown(false)}
          heading="программу"
        />
      )}
    </Container>
  );
};
