import React, { useRef, useState, useLayoutEffect } from "react";
import { ReferenceAttribute } from "../ReferenceAttribute";
import { NumberInput, TextArea } from "./styles";
import { CustomCheckbox } from "../../../UI/CustomCheckbox";

export const SingleAttribute = ({
  attribute,
  stageId,
  handleKeyPress,
  handleCheckboxChange,
}) => {
  const oldValue = useRef(null);
  const [newAttrValue, setNewAttrValue] = useState(attribute.value);

  const [rows, setRows] = React.useState(1);

  useLayoutEffect(() => {
    if (typeof newAttrValue.value === "string") {
      const rowsToSet =
        Math.round(newAttrValue.value.length / 24) > 1
          ? Math.round(newAttrValue.value.length / 24)
          : 1;

      setRows(rowsToSet);
    }
  }, [newAttrValue.value]);

  if (typeof newAttrValue.value === "number") {
    return (
      <NumberInput
        value={newAttrValue.value}
        title="Только целые числа"
        type="number"
        step="1"
        onFocus={() => {
          oldValue.current = attribute.value;
        }}
        onChange={(e) =>
          setNewAttrValue({
            ...newAttrValue,
            value: parseInt(e.target.value, 10),
          })
        }
        onBlur={() => {
          setNewAttrValue(oldValue.current);
        }}
        onKeyPress={(e) => {
          e.stopPropagation();
          handleKeyPress(e, {
            multiline_stub: newAttrValue.multiline_stub,
            value: Math.floor(newAttrValue.value),
          });
          setNewAttrValue({
            ...newAttrValue,
            value: newAttrValue.value,
          });
        }}
      />
    );
  }

  if (newAttrValue.value === true || newAttrValue.value === false) {
    const handleChange = () => {
      const newObj = { ...newAttrValue };
      newObj.value = !newAttrValue.value;
      handleCheckboxChange(!newAttrValue.value);
      setNewAttrValue(newObj);
    };

    return (
      <>
        <CustomCheckbox checked={newAttrValue.value} onChange={handleChange} />
        <span style={{ marginLeft: "10px" }}>
          {newAttrValue.value ? "Да" : "Нет"}
        </span>
      </>
    );
  }

  if (!attribute.type.app_type.includes("Справочник")) {
    return (
      <TextArea
        rows={rows}
        value={newAttrValue.value}
        onFocus={() => {
          oldValue.current = attribute.value;
        }}
        onChange={(e) =>
          setNewAttrValue({
            ...newAttrValue,
            value: e.target.value,
          })
        }
        onBlur={() => {
          setNewAttrValue(oldValue.current);
        }}
        onKeyPress={(e) => {
          handleKeyPress(e, {
            multiline_stub: newAttrValue.multiline_stub,
            value: newAttrValue.value,
          });
          setNewAttrValue({
            ...newAttrValue,
            value: newAttrValue.value,
          });
        }}
      />
    );
  }

  return <ReferenceAttribute stageId={stageId} attribute={attribute} />;
};
