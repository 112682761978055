import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 296px;
  height: min-content;
  background-color: ${({ status }) => {
    if (status === "new") return "#FBE7DC";
    if (status === "inprogress") return "#FBF7DC";
    return "#E1FBDC";
  }};
  padding: 14px 0 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: #203b54;

  input {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  &:not(:last-child)::after {
    position: absolute;
    top: 26px;
    right: -40px;
    display: block;
    content: " ";
    background-image: url("/stageArrowIcon.svg");
    background-size: 28px 28px;
    height: 28px;
    width: 28px;
  }

  &:hover {
    box-shadow: 0 10px 44px rgba(108, 126, 147, 0.15);
    border-radius: 12px;
  }

  transition: all 0.2s;
`;

export const Status = styled.div`
  margin: 0 18px 0 24px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: ${({ status }) => {
    if (status === "new") return "#D95A45";
    if (status === "inprogress") return "#C4AD18";
    return "#049478";
  }};
`;

export const StatusContainer = styled.div`
  display: flex;

  img {
    cursor: pointer;
  }
`;

export const Header = styled.h2`
  margin: 14px 24px;
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const AddButton = styled.button`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  text-decoration-line: underline;
  color: #203b54;
  background-color: ${({ status }) => {
    if (status === "new") return "#F7D9C8";
    if (status === "inprogress") return "#F2EBBE";
    return "#D2F2CC";
  }};
  border: none;
  padding: 10px 0 10px 24px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    color: #d95a45;

    circle {
      fill: #d95a45;
      stroke: #d95a45;
    }
  }

  svg {
    margin-right: 12px;
  }
`;

export const Number = styled.div`
  position: absolute;
  top: -14px;
  left: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  background-color: #fff;
  border: 1px solid #dee2ea;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Responsible = styled.div`
  padding: 0 24px 5px;
  border-bottom: ${({ status }) => {
    if (status === "new") return "1px solid #E5BFAA";
    if (status === "inprogress") return "1px solid #E6DEA7";
    return "1px solid #ADDCA4";
  }};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;

  span {
    display: block;
    font-weight: 300;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #7a8fa3;
    margin-bottom: 5px;
  }
`;

export const AttributeList = styled.ul`
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  min-height: 40px;
  position: relative;
  padding: 0 24px;

  > div:first-child {
    margin-bottom: 4px;
  }
`;

export const CirclesContainer = styled.div`
  display: flex;

  &:first-of-type {
    margin-top: 6px;
  }

  &:last-of-type {
    margin-bottom: 6px;
    flex-wrap: wrap;
  }

  img {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    align-self: center;
  }
`;

export const ProcessCircle = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #dee2ea;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
`;

export const AddConnectionButton = styled.button`
  background-color: transparent;
  border: transparent;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    path {
      fill: #fff;
    }
    circle {
      fill: #203b54;
    }
  }
`;

export const Stop = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -14px;
  top: 44px;
  background: #ff5555;
  border: 1px solid #dee2ea;
  border-radius: 50px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    transform: translateY(-5%);
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 26px;
  width: 100%;
  background-color: ${({ status }) => {
    if (status === "new") return "#F7D9C8";
    if (status === "inprogress") return "#F2EBBE";
    return "#D2F2CC";
  }};
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  border-radius: 0 0 10px 10px;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
`;
