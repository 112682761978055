import styled from "styled-components";
import arrowTop from "../../../assets/arrowTop.svg";
import arrowBottom from "../../../assets/arrowBottom.svg";

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: #414141;
  opacity: 0.3;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 42%;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  max-height: 90vh;

  transform: translateX(-50%) translateY(-50%);

  z-index: 100;
  background: #f8fafc;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;
