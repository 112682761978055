import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { Loader } from "../../Loaders";

import {
  CloseButton,
  Form,
  Input,
  Label,
  SubmitButton,
  Header,
  DragAndDrop,
  Aside,
  Textarea,
} from "./styles";
import { Modal } from "../Modal";

export const EmployeeModal = ({
  isEdit,
  employee,
  isLoading,
  onCreate,
  onEdit,
  onClose,
}) => {
  const [name, setName] = useState(employee?.name || "");
  const [comment, setComment] = useState(employee?.comment || "");
  const [files, setFiles] = useState([]);
  const [base64, setBase64] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = (e) => {
        setBase64(e.target.result.split("base64,")[1]);
      };

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbsExist = (
    <div key={employee?.name}>
      <div>
        <img
          style={{ borderRadius: "100px" }}
          src={employee?.photo}
          alt="photo"
        />
      </div>
    </div>
  );

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          style={{
            borderRadius: "100px",
            maxWidth: "120px",
            maxHeight: "120px",
          }}
          src={file.preview}
          alt="avatar"
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // removed the dep: [files] and added return
  });

  const buttonText = isEdit ? "Сохранить" : "Создать";

  const handleSubmit = (e) => {
    e.preventDefault();
    return isEdit
      ? onEdit({
          name,
          photo: base64,
          communication: "tel: 8-800-900...",
          comment,
          ...(files[0] && { fileName: files[0].name }),
        })
      : onCreate({
          name,
          photo: base64,
          ...(files[0] && { fileName: files[0].name }),
          communication: "tel: 8-800-900...",
          comment,
        });
  };

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form onSubmit={handleSubmit}>
        <Header>{isEdit ? "Редактирование" : "Добавление"} сотрудника</Header>

        <Label>
          Имя
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <DragAndDrop {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Загрузить фото</p>
          {files.length > 0 ? thumbs : <img src="/noAvatar.svg" alt="" />}
          <p>Выбрать</p>
        </DragAndDrop>
        <Aside>{employee && thumbsExist}</Aside>

        <Label>
          Комментарий
          <Textarea
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : buttonText}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
