import React, { useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { useAuth } from "../../hooks/useAuth";
import {
  Container,
  BusinessSpace,
  Profile,
  Search,
  SearchInput,
  BusinessButton,
  HandbookButton,
  SpaceAndProfile,
  StyledMenuItem,
  InfoItem,
  ExitItem,
  EditItem,
  Separator,
} from "./styles";
import { baseUrl, getSubdomainName } from "../../api";
import { MainLoader } from "../UI/Loaders/MainLoader";

export const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, setIsAuth, setUserName, signOut } = useAuth();

  const { isLoading, data: response } = useQuery("user", () =>
    axios
      .get(`${baseUrl}/api/user`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        setIsAuth(false);
        window.localStorage.removeItem("token");
        throw new Error(err.message);
      })
  );

  useEffect(() => {
    setUserName(response?.data?.data?.user?.name);
  }, [response]);

  return (
    <Container>
      <div style={{ display: "flex" }}>
        <img
          src="/logo.svg"
          alt="logo"
          onClick={() => navigate("/catalog")}
          style={{ cursor: "pointer" }}
        />
        <BusinessButton to="/catalog">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_92_72)">
              <path
                d="M3.9375 7.31689C5.80146 7.31689 7.3125 5.80586 7.3125 3.94189C7.3125 2.07793 5.80146 0.566895 3.9375 0.566895C2.07354 0.566895 0.5625 2.07793 0.5625 3.94189C0.5625 5.80586 2.07354 7.31689 3.9375 7.31689Z"
                stroke="#D95A45"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.9375 17.4419C5.80146 17.4419 7.3125 15.9309 7.3125 14.0669C7.3125 12.2029 5.80146 10.6919 3.9375 10.6919C2.07354 10.6919 0.5625 12.2029 0.5625 14.0669C0.5625 15.9309 2.07354 17.4419 3.9375 17.4419Z"
                stroke="#D95A45"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0625 7.31689C15.9265 7.31689 17.4375 5.80586 17.4375 3.94189C17.4375 2.07793 15.9265 0.566895 14.0625 0.566895C12.1985 0.566895 10.6875 2.07793 10.6875 3.94189C10.6875 5.80586 12.1985 7.31689 14.0625 7.31689Z"
                stroke="#D95A45"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0625 17.4419C15.9265 17.4419 17.4375 15.9309 17.4375 14.0669C17.4375 12.2029 15.9265 10.6919 14.0625 10.6919C12.1985 10.6919 10.6875 12.2029 10.6875 14.0669C10.6875 15.9309 12.1985 17.4419 14.0625 17.4419Z"
                stroke="#D95A45"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_92_72">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Бизнес-процессы</span>
        </BusinessButton>

        <Menu
          menuButton={({ open }) => (
            <HandbookButton
              open={open}
              selected={pathname.includes("directory")}
            >
              <svg
                width="17"
                height="22"
                viewBox="0 0 17 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_92_82)">
                  <path
                    d="M16.5526 5.90332C15.3966 4.71899 15.3778 1.6069 16.5526 0.40332H2.88194C1.6463 0.40332 0.447357 1.42907 0.447357 2.69499V3.61165C0.447357 4.87757 1.6463 5.90332 2.88194 5.90332H16.5526V21.4867H3.3293H2.43457C1.19894 21.4867 0.447357 20.4618 0.447357 19.195V3.61165"
                    stroke="#4D6276"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.6842 3.15332H15.6579"
                    stroke="#4D6276"
                    strokeLinecap="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.51758 12.6255C6.37653 14.3553 7.40816 15.4058 9.09653 16.2922L10.5344 15.1803C10.6954 15.0153 10.9334 14.9603 11.1428 15.0336C11.8103 15.2591 12.5323 15.3819 13.2723 15.3819C13.5997 15.3819 13.8682 15.6569 13.8682 15.9924V18.1255C13.8682 18.4619 13.5997 18.7369 13.2723 18.7369C7.67121 18.7369 3.13132 14.0858 3.13132 8.34744C3.13132 8.01194 3.39974 7.73694 3.72811 7.73694H5.81553C6.1439 7.73694 6.41232 8.01194 6.41232 8.34744C6.41232 9.11194 6.53132 9.84527 6.75232 10.5291C6.81763 10.7436 6.77021 10.9819 6.6029 11.1524L5.51758 12.6255V12.6255Z"
                    stroke="#4D6276"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_92_82">
                    <rect width="17" height="22" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Справочники</span>
            </HandbookButton>
          )}
          transition
        >
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/employees");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Сотрудники
          </StyledMenuItem>
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/forms");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Формы
          </StyledMenuItem>
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/documents");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Документы
          </StyledMenuItem>
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/programs");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Программы
          </StyledMenuItem>
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/roles");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Роли
          </StyledMenuItem>
          <StyledMenuItem
            onContextMenu={(e) => e.preventDefault()}
            onClick={(e) => {
              navigate("/directory/stages-attributes");
              e.syntheticEvent.stopPropagation();
            }}
          >
            Атрибуты этапов
          </StyledMenuItem>
        </Menu>

        <Search>
          <img src="/search.svg" alt="search icon" />
          <SearchInput placeholder="Поиск..." />
        </Search>
      </div>
      {isLoading ? (
        <MainLoader />
      ) : (
        <SpaceAndProfile>
          <BusinessSpace>
            {response.data.data.user.business_space}
          </BusinessSpace>

          <Menu
            align="end"
            menuButton={
              <Profile>
                <img
                  style={{
                    width: "39px",
                    height: "39px",
                    minWidth: "39px",
                    minHeight: "39px",
                    borderRadius: "50%",
                  }}
                  src={response.data.data.user.photo || "/noAvatar.svg"}
                  alt="profile picture"
                />
              </Profile>
            }
            transition
          >
            <InfoItem onContextMenu={(e) => e.preventDefault()}>
              <div>{response?.data?.data?.user?.name}</div>
              <div>
                <span>Email</span>
                <span>{response?.data?.data?.user?.login}</span>
              </div>
            </InfoItem>
            <Separator />
            <ExitItem onClick={signOut}>
              <img src="/logoutIcon.svg" alt="exit" />
              Выйти
            </ExitItem>
          </Menu>
        </SpaceAndProfile>
      )}
    </Container>
  );
};
