import styled from "styled-components";

import arrowTop from "../../assets/arrowTop.svg";
import arrowBottom from "../../assets/arrowBottom.svg";

export const Container = styled.div`
  height: calc(100vh - 80px);
`;

export const Breadcrumbs = styled.div`
  width: 100%;
  height: 42px;
  background: #eff3f9;
  border-top: 1px solid #cbd0dc;
  border-bottom: 1px solid #cbd0dc;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
`;

export const Details = styled.div`
  width: 100%;
  height: 187px;
  padding: 26px 40px 0;
  border-top: 1px solid #cbd0dc;

  > div {
    margin-bottom: 20px;
  }

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
  }

  span {
    font-weight: 300;
    font-size: 13px;
    color: #7a8fa3;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #203b54;
    margin: 0;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: ${({ isItemSelected }) =>
    isItemSelected ? "calc(100vh - 321px)" : "calc(100vh - 122px)"};
  background-color: #fafcfe;
`;

export const MainInner = styled.div`
  height: ${({ isItemSelected }) =>
    isItemSelected ? "calc(100vh - 411px)" : "calc(100vh - 212px)"};
  padding: 0 40px;
  background-color: #fafcfe;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;

export const Name = styled.div`
  span {
    height: 18px;
    margin-bottom: 5px;
  }
  p {
    height: 20px;
  }
`;

export const Commentary = styled.div`
  p {
    width: 70%;
  }
`;

export const Author = styled.div`
  span {
    height: 18px;
  }
  p {
    height: 26px;
  }
`;

export const AddRoleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px;
  margin: 0 auto;
  height: 40px;
  border: 1px solid #d95a45;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;

  img {
    margin-right: 16px;
  }
`;
