import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  width: 100%;
  padding-left: 22px;
`;

export const SelectElement = styled(Select)`
  .react-select-container {
    // custom styles
    padding: 0;
  }

  .react-select__menu {
  }

  .react-select__control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    align-items: start;
    min-height: 26px;
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicators {
    // custom styles
    visibility: hidden;
    display: none;
  }
`;
