import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LoginPage } from "../../pages/LoginPage/LoginPage";
import { RequireAuth } from "../../hoc/RequireAuth";
import { Layout } from "../Layout";
import { CatalogPage } from "../../pages/CatalogPage";
import { DashboardPage } from "../../pages/DashboardPage";
import { NotFoundPage } from "../../pages/NotFoundPage/NotFoundPage";
import { DirectoryPage } from "../../pages/DirectoryPage";
import { Employees } from "../Employees";
import { Forms } from "../Forms";
import { Documents } from "../Documents";
import { Programs } from "../Programs";
import { StagesPage } from "../../pages/StagesPage";
import { StagesAttributes } from "../StagesAttributes";
import { Roles } from "../Roles";

export const Router = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<Navigate to="catalog" />} />

        <Route
          path="catalog"
          element={
            <RequireAuth>
              <CatalogPage />
            </RequireAuth>
          }
        />

        <Route
          path="catalog/:id"
          element={
            <RequireAuth>
              <CatalogPage />
            </RequireAuth>
          }
        />

        <Route path="view/:id" element={<DashboardPage />} />
        <Route path="edit/:id" element={<StagesPage />} />

        <Route path="directory" element={<Navigate to="employees" />} />
        <Route path="directory" element={<DirectoryPage />}>
          <Route path="employees" element={<Employees />} />
          <Route path="forms" element={<Forms />} />
          <Route path="documents" element={<Documents />} />
          <Route path="programs" element={<Programs />} />
          <Route path="roles" element={<Roles />} />
          <Route path="stages-attributes" element={<StagesAttributes />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
