import styled from "styled-components";
import { Menu } from "react-contexify";
import { NavLink } from "react-router-dom";
import { MenuItem } from "@szhsin/react-menu";

export const Container = styled.div`
  width: 100vw;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2ea;
  padding-left: 37px;
`;

export const BusinessSpace = styled.div`
  margin-left: 83px;
  color: #4d6276;
`;

export const Profile = styled.div`
  margin-left: 23px;
  display: flex;
  position: relative;
`;

export const ProfileInfo = styled.div`
  margin-left: 21px;
`;

export const ProfileName = styled.div`
  font-weight: 600;
`;

export const ProfileData = styled.div`
  color: #a6b1bb;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 3px;

  span {
    margin-left: 8px;
    color: #4d6276;
    font-weight: 600;
  }
`;

export const Search = styled.div`
  margin-left: 50px;
  width: 428px;
  height: 45px;
  border: 1px solid #e7edef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #969eae;
  padding-left: 22px;

  input {
    font-size: 16px;
    outline: none;
  }
`;

export const SearchInput = styled.input`
  border: none;
  margin-left: 16px;
  width: 100%;
`;

export const BusinessButton = styled(NavLink)`
  margin-left: 37px;
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: 184px;

  span {
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #4d6276;
    font-family: Noto Sans, serif;
  }

  svg {
    min-width: 18px;
    min-height: 18px;
    path {
      stroke: #4d6276;
    }
  }

  &[class*="active"] {
    span {
      color: #d95a45;
      font-weight: 600;
      transition: all 0.2s;
    }

    svg {
      path {
        stroke: #d95a45;
      }
    }
  }

  &:hover {
    span {
      color: #d95a45;
      transition: all 0.2s;
    }

    svg {
      path {
        stroke: #d95a45;
        transition: all 0.2s;
      }
    }
  }
`;

export const HandbookButton = styled.div`
  margin-left: 37px;
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: 144px;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #4d6276;

  span {
    margin-left: 20px;
    color: ${({ selected, open }) =>
      selected || open ? "#d95a45" : "#4d6276"};
    font-weight: ${({ selected }) => (selected ? 600 : 400)};
  }

  svg {
    min-width: 18px;
    min-height: 18px;
    path {
      stroke: ${({ selected, open }) =>
        selected || open ? "#d95a45" : "#4d6276"};
    }
  }

  &:hover,
  :active {
    span {
      color: #d95a45;
    }

    svg {
      path {
        stroke: #d95a45;
      }
      transition: all 0.2s;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &:hover {
    color: #d95a45;
    background-color: #e7eaf0;
  }
`;

export const SpaceAndProfile = styled.div`
  display: flex;
  padding-right: 85px;
  align-items: center;
`;

export const InfoItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0;

  > div {
    margin-bottom: 15px;
  }

  > div:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    > span:first-child {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      letter-spacing: 0.0041em;
      color: #7a8fa3;
    }

    > span:last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.0041em;
      color: #203b54;
    }
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

export const EditItem = styled(MenuItem)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #d95a45;
  padding: 15px 24px 15px;

  > img {
    margin-right: 12px;
  }
`;

export const ExitItem = styled(MenuItem)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  padding: 15px 24px;

  > img {
    margin-right: 12px;
  }
`;

export const Separator = styled(MenuItem)`
  margin: 0 16px;
  padding: 0;
  height: 0;
  border: 1px solid #cbd0dc;
`;

export const StyledContextMenu = styled(Menu)`
  position: absolute;
  padding: 5px 0;
  background: #fefefe;
  border: 1px solid #e7eaf0;
  box-sizing: border-box;
  box-shadow: -2px 4px 8px rgba(113, 128, 158, 0.25);
`;

// export const StyledMenuItem = styled(Item)`
//   padding: 10px 22px 16px;
//   font-weight: 400;
//   font-size: 14px;
//   color: #203b54;
//   cursor: pointer;
//
//   &:hover {
//     background: #e7eaf0;
//   }
// `;
