import React from "react";
import {
  Container,
  HiddenRadioButton,
  RadioButton,
  RadioContainer,
  RadioIcon,
} from "./styles";

export const CustomRadioButtons = ({
  label,
  name,
  defaultChecked,
  onChange,
}) => {
  return (
    <Container onChange={onChange}>
      <span>{label}</span>
      <RadioContainer>
        <HiddenRadioButton
          name={name}
          id="true"
          value="true"
          defaultChecked={defaultChecked}
        />
        <RadioButton>
          <RadioIcon />
        </RadioButton>
        Да
      </RadioContainer>

      <RadioContainer>
        <HiddenRadioButton
          name={name}
          id="false"
          value="false"
          defaultChecked={!defaultChecked}
        />
        <RadioButton>
          <RadioIcon />
        </RadioButton>
        Нет
      </RadioContainer>
    </Container>
  );
};
