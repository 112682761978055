import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  color: #7a8fa3;
  margin-top: 20px;

  span {
    width: 194px;

    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #7a8fa3;
  }
`;

export const RadioContainer = styled.label`
  display: flex;

  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;

  &:last-of-type {
    margin-left: 20px;
  }
`;

export const HiddenRadioButton = styled.input.attrs(({ defaultChecked }) => ({
  defaultChecked,
  type: "radio",
}))`
  height: 21px;
  width: 21px;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const RadioButton = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 1px solid #7a8fa3;
  background-color: white;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  ${HiddenRadioButton}:checked + && {
    > div {
      background-color: #d95a45;
    }
  }
`;

export const RadioIcon = styled.div`
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
`;
