import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import {
  Container,
  MainData,
  TopBar,
  TopBarButton,
  Details,
  Commentary,
  StyledContextMenu,
  StyledMenuItem,
  Avatar,
  Separator,
  Breadcrumbs,
  Name,
  Author,
  CatalogLoader,
} from "./styles";
import { CreateModal } from "../../components/UI/Modals/CreateModal";
import { ConfirmDeleteModal } from "../../components/UI/Modals/ConfirmDeleteModal";
import { baseUrl, getSubdomainName } from "../../api";
import CatalogItem from "../../components/CatalogItem/CatalogItem";
import { EditModal } from "../../components/UI/Modals/EditModal";

export const CatalogPage = () => {
  const queryClient = useQueryClient();
  const { token, userName } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [isProcess, setIsProcess] = useState(false);
  const [isCreateModalShown, setIsCreateModalShown] = useState(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [isMine, setIsMine] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery(["getAllCatalogItems", params.id], async () =>
    axios.get(
      `${baseUrl}/catalog/processes/${
        params.id ? `?parent_id=${params.id}` : ""
      }`,
      {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );

  const createMutation = useMutation(
    "addItem",
    async ({ name, parentId, isFolder, comment }) => {
      await axios.post(
        `${baseUrl}/catalog/processes`,
        {
          name,
          parent_id: parentId,
          is_folder: isFolder,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllCatalogItems", params.id]);
        setIsCreateModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editItem",
    async ({ name, parentId, comment }) => {
      await axios.put(
        `${baseUrl}/catalog/processes/${itemToEdit.id}`,
        {
          name,
          parent_id: `${parentId}`,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsEditModalShown(false);
        queryClient.invalidateQueries("getAllCatalogItems");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteItem",
    async (id) => {
      await axios.delete(`${baseUrl}/catalog/processes/${id}`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        setIsDeleteModalShown(false);
        queryClient.invalidateQueries("getAllCatalogItems");
      },
    }
  );

  const toggleFavouriteMutation = useMutation(
    "toggleFavourite",
    async ({ id, isFavouriteFlag }) => {
      await axios.post(
        `${baseUrl}/catalog/processes/favourite`,
        {
          id,
          is_favourite: isFavouriteFlag,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllCatalogItems");
      },
    }
  );

  const toggleFavourite = (id, isFav) => {
    const data = {
      id,
      isFavouriteFlag: !isFav,
    };
    toggleFavouriteMutation.mutate(data);
  };

  const filter = (array) => {
    if (isMine && isFavourite) {
      return array
        .filter((item) => item.author.name === userName)
        .filter((item) => item.is_favourite === true);
    }
    if (isFavourite) {
      return array.filter((item) => item.is_favourite === true);
    }
    if (isMine) {
      return array.filter((item) => item.author.name === userName);
    }
    return array;
  };

  const closeModal = (cb) => {
    cb(false);
  };

  const chooseFilter = (condition) => {
    if (condition === "all") {
      setIsFavourite(false);
      setIsMine(false);
    }
    if (condition === "favourite") {
      setIsFavourite(true);
      setIsMine(false);
    }
    if (condition === "mine") {
      setIsFavourite(false);
      setIsMine(true);
    }
  };

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleClick = (e, item) => {
    e.stopPropagation();
    switch (e.detail) {
      case 1:
        e.stopPropagation();
        setSelectedItem(item);
        if (e.target.id === "star" || e.target.id === "star") {
          toggleFavourite(item.id, item.is_favourite);
        }
        break;
      case 2:
        navigate(item.is_folder ? `/catalog/${item.id}` : `/view/${item.id}`);
        break;
      default:
        break;
    }
  };

  const handleDownloadExcel = (id) => {
    axios
      .get(`${baseUrl}/catalog/processes/${id}/excel`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((resp) => {
        const filename = resp.headers["content-disposition"]
          .split("filename=")[1]
          .split('"')[1];
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", decodeURI(filename));
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  if (isLoading) return <CatalogLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <Container>
      <TopBar>
        <TopBarButton
          active={!isFavourite && !isMine}
          onClick={() => chooseFilter("all")}
        >
          Все
        </TopBarButton>
        <TopBarButton
          active={isFavourite}
          onClick={() => chooseFilter("favourite")}
        >
          Избранные
        </TopBarButton>
        <TopBarButton active={isMine} onClick={() => chooseFilter("mine")}>
          Мои процессы
        </TopBarButton>
      </TopBar>

      <Separator />
      <Breadcrumbs>
        {[
          {
            id: 0,
            name: "Бизнес-процессы",
          },

          ...response.data.data.bread_crumbs,
        ].map((crumb, index, array) => {
          if (index !== array.length - 1) {
            return (
              <Link to={`/catalog/${crumb.id}`} key={crumb.id}>
                <span>{crumb.name}</span>
                <img
                  style={{ margin: "0 10px" }}
                  src="/breadCrumbArrow.svg"
                  alt="arrow"
                />
              </Link>
            );
          }
          return (
            <Link to={`/catalog/${crumb.id}`} key={crumb.id}>
              <span>{crumb.name}</span>
            </Link>
          );
        })}
      </Breadcrumbs>

      <div
        style={{ height: `${selectedItem ? "" : "100%"}` }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedItem(null);
        }}
      >
        <MainData id="primary" storeRef={false} isSelected={selectedItem}>
          {filter(response?.data?.data?.processes).map((item) => (
            <CatalogItem
              key={item.id}
              data={item}
              item={item}
              selected={selectedItem?.id === item.id}
              onClick={(e) => handleClick(e, item)}
            />
          ))}
        </MainData>
      </div>

      <StyledContextMenu id="primary">
        <StyledMenuItem
          onClick={() => {
            setIsProcess(false);
            setIsCreateModalShown(true);
          }}
        >
          Создать группу
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            setIsProcess(true);
            setIsCreateModalShown(true);
          }}
        >
          Создать процесс
        </StyledMenuItem>
      </StyledContextMenu>

      <StyledContextMenu id="secondary">
        <StyledMenuItem
          onClick={(data) =>
            navigate(
              data.props.is_folder
                ? `/catalog/${data.props.id}`
                : `/view/${data.props.id}`
            )
          }
        >
          Просмотр
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(data) =>
            toggleFavourite(data.props.id, data.props.is_favourite)
          }
        >
          Избранное
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(data) => {
            setItemToEdit(data.props);
            setIsEditModalShown(true);
          }}
        >
          Редактировать
        </StyledMenuItem>

        <StyledMenuItem
          onClick={(data) => handleDownloadExcel(data.props.id)}
          disabled={(data) => data.props.is_folder}
        >
          Выгрузить в Excel
        </StyledMenuItem>

        <StyledMenuItem
          onClick={(data) => {
            setIsProcess(!data.props.is_folder);
            setItemToDeleteId(data.props.id);
            setIsDeleteModalShown(true);
          }}
        >
          Удалить
        </StyledMenuItem>
      </StyledContextMenu>

      {selectedItem && (
        <Details>
          <Name>
            <span>Наименование группы</span>
            <p>{selectedItem ? selectedItem.name : " "}</p>
          </Name>
          <Author>
            <span>Автор</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>{selectedItem ? selectedItem.author.name : " "}</p>
              {selectedItem?.author?.photo && (
                <Avatar src={selectedItem?.author?.photo} alt="pic" />
              )}
            </div>
          </Author>
          <Commentary>
            <span>Комментарий</span>
            <p>{selectedItem ? selectedItem.comment : " "}</p>
          </Commentary>
        </Details>
      )}

      {isCreateModalShown && (
        <CreateModal
          parentId={
            response?.data?.data?.bread_crumbs[
              response.data.data.bread_crumbs.length - 1
            ]?.id || 0
          }
          isProcess={isProcess}
          isLoading={createMutation.isLoading}
          createItem={handleCreate}
          onClose={() => closeModal(() => setIsCreateModalShown())}
        />
      )}
      {isDeleteModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteMutation.isLoading}
          id={itemToDeleteId}
          onDelete={handleDelete}
          onClose={() => closeModal(() => setIsDeleteModalShown())}
          heading={isProcess ? "процесс" : "группу"}
        />
      )}
      {isEditModalShown && (
        <EditModal
          isProcess={isProcess}
          itemToEdit={itemToEdit}
          isLoading={editMutation.isLoading}
          onEdit={handleEdit}
          onClose={() => closeModal(() => setIsEditModalShown())}
        />
      )}
    </Container>
  );
};
