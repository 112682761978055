import React from "react";

import { TooltipCard, TooltipText, TooltipBox } from "./styles";

export const Tooltip = ({ text, tip, required }) => {
  return (
    <TooltipCard>
      <TooltipText>
        {text}
        &nbsp;{required && "*"}
      </TooltipText>
      <TooltipBox>{tip}</TooltipBox>
    </TooltipCard>
  );
};
