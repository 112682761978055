import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 246px;
  background: #ffffff;
  border: 1px solid #dbe0e9;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: ${({ selected }) =>
    selected && "0px 10px 44px rgba(108, 126, 147, 0.15)"};

  &:hover {
    box-shadow: 0 5px 20px rgba(108, 126, 147, 0.15);
    box-shadow: ${({ selected }) =>
      selected && "0px 10px 44px rgba(108, 126, 147, 0.15)"};
  }

  transition: all 0.2s;
`;

export const Delete = styled.img`
  position: absolute;
  top: 10px;
  right: 15px;
  width: 14px;
  height: 16px;
`;

export const ProfilePic = styled.img`
  margin: 26px 0 15px;
  width: 72px;
  height: 72px;
  border-radius: 50px;
`;

export const Name = styled.div`
  width: 60%;
  height: 40px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.0041em;
  color: #203b54;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Processes = styled.div`
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #a6b1bb;

  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: 0.0041em;
    color: #4d6276;
  }
`;

export const EditButton = styled.button`
  width: 100%;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  text-decoration-line: underline;
  color: #d95a45;
  border: none;
  border-top: 1px solid #e4e7ef;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 16px;
  }
`;
