import React from "react";

import { Container } from "./styles";
import { ReferenceAttribute } from "../ReferenceAttribute";
import { AddValueButton } from "../styles";

export const MultipleAttribute = ({
  status,
  attribute,
  stageId,
  onDelete,
  handleAdd,
}) => {
  return (
    <Container>
      {attribute.values.map((value, index) => {
        return (
          <div
            key={`${JSON.stringify(attribute)}${index}`}
            style={{ padding: "0 24px 0 0" }}
          >
            <ReferenceAttribute
              key={`${JSON.stringify(attribute)}${index}`}
              attrId={attribute.id}
              attrType={attribute.type.app_type}
              value={value}
              stageId={stageId}
            />

            <img
              src="/deleteAttributeValueIcon.svg"
              alt=""
              onClick={() => onDelete(attribute.id, value.multiline_stub)}
            />
          </div>
        );
      })}
      <AddValueButton status={status} onClick={() => handleAdd(attribute.id)}>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9.5" cy="9.5" r="9" stroke="#203B54" />
          <path
            d="M13.151 9.80921H10.169V12.8332H8.97897V9.80921H6.01097V8.73121H8.97897V5.69321H10.169V8.73121H13.151V9.80921Z"
            fill="#203B54"
          />
        </svg>
        Добавить значение
      </AddValueButton>
    </Container>
  );
};
