import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 56px 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 276px;
`;

export const Button = styled.button`
  width: 125px;
  height: 46px;
  background: ${(props) => (props.red ? "#d95a45" : "transparent")};
  color: ${(props) => (props.red ? "#FBFBFB" : "#d95a45")};
  border-radius: 10px;
  border: ${(props) => (props.red ? "none" : "1px solid #D95A45")};
`;

export const Header = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #203b54;
  margin-bottom: 25px;
`;
