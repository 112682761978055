import styled from "styled-components";

export const StyledLoader = styled.div`
  /*  control spinner size with setting font-size  */
  font-size: 10px;
  border: 0.4em solid #fff;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
  animation: loading-spinner 2s linear infinite;
  margin: 0 auto;
  box-sizing: border-box;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
