import React from "react";

import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, AttributeName, AttributeValue } from "./styles";
import { MultipleAttribute } from "./MultipleAttribute";
import { baseUrl, getSubdomainName } from "../../../api";
import { useAuth } from "../../../hooks/useAuth";
import { SingleAttribute } from "./SingleAttribute";
import { icons } from "../../StagesAttributes/AttributeCard/constants";
import { Tooltip } from "../../Tooltip";

export const Attribute = ({ attribute, status, stageId }) => {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const params = useParams();

  const createMutation = useMutation(
    "addLine",
    async (data) => {
      await axios.post(
        `${baseUrl}/catalog/stages_multiline_value_row/${stageId}`,
        {
          attribute_id: data,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", params.id]);
      },
    }
  );

  const editMutation = useMutation(
    "editLine",
    async ({ attributeId, multilineStub, value }) => {
      await axios.put(
        `${baseUrl}/catalog/stage_attribute_edit/${stageId}`,
        {
          attribute_id: attributeId,
          multiline_stub: multilineStub,
          value,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", params.id]);
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteLine",
    async ({ attrId, multilineStub }) => {
      await axios.delete(
        `${baseUrl}/catalog/stages_multiline_value_row/${stageId}`,
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
          data: {
            attribute_id: attrId,
            multiline_stub: multilineStub,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", params.id]);
      },
    }
  );

  const handleAdd = (data) => {
    createMutation.mutate(data);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (attrId, multilineStub) => {
    deleteMutation.mutate({ attrId, multilineStub });
  };

  const handleKeyPress = (event, data) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
      handleEdit({
        attributeId: attribute.id,
        multilineStub: data.multiline_stub || 0,
        value: data.value,
      });
    }
  };

  const handleCheckboxChange = (data) => {
    handleEdit({
      attributeId: attribute.id,
      multilineStub: data.multiline_stub || 0,
      value: data,
    });
  };

  const displayedAttribute = attribute.multiple_values ? (
    <MultipleAttribute
      status={status}
      onDelete={handleDelete}
      stageId={stageId}
      attribute={attribute}
      handleAdd={handleAdd}
    />
  ) : (
    <SingleAttribute
      handleKeyPress={handleKeyPress}
      stageId={stageId}
      attribute={attribute}
      handleCheckboxChange={handleCheckboxChange}
    />
  );

  const selectIcon = (type) => {
    if (type === "Булево") return icons.boolean;
    if (type === "Строка") return icons.string;
    if (type === "Число") return icons.number;
    return icons.directories;
  };

  return (
    <Container status={status}>
      <AttributeName>
        <div style={{ marginTop: "2px" }}>
          <Tooltip
            text={selectIcon(attribute.type.app_type)}
            tip={
              attribute.type.app_type.includes("Справочник")
                ? "Справочник"
                : attribute.type.app_type
            }
          />
        </div>

        <Tooltip
          required={attribute.required}
          text={attribute.name}
          tip={attribute.name}
        />
      </AttributeName>
      <AttributeValue>{displayedAttribute}</AttributeValue>
    </Container>
  );
};
