import styled from "styled-components";
import { Button } from "../Buttons";

export const StyledButton = styled(Button)`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 20px;
  padding: 9px 22px;
  height: 40px;
  border: 1px solid #d95a45;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
  font-family: Noto sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }

  &:hover {
    path {
      fill: #fff;
    }
  }
`;
