import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background: ${({ isSelected }) => (isSelected ? "#E8ECEF" : "#FFFFFF")};
  border: 1px solid #dbe0e9;
  border-radius: 7px;
  margin-bottom: 5px;
  padding: 14px 12px 14px 18px;
  cursor: pointer;

  &:hover {
    background-color: #f2f5f7;
    background-color: ${({ isSelected }) => isSelected && "#E8ECEF"};
  }

  transition: background-color 0.2s;

  > div {
    display: flex;
  }
`;

export const EditButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  text-decoration-line: underline;
  color: #d95a45;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 54px;
  cursor: pointer;

  padding: 0;

  img {
    margin-right: 16px;
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Number = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #a6b1bb;
  margin-right: 5px;
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
`;

export const Type = styled.div`
  margin-right: 48px;
  display: flex;

  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #7a8fa3;

    &:first-of-type {
      margin-right: 12px;
    }
  }

  svg {
    margin-top: 2px;
  }
`;

export const Required = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 48px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #7a8fa3;

  > div {
    margin-left: 14px;
  }

  input {
    margin-left: 14px;
  }
`;

export const Utility = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonTop = styled.button`
  position: absolute;
  top: auto;
  left: 34px;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    path {
      fill: #d95a45;
    }
    &:active {
      rect {
        stroke: #d95a45;
      }
    }
  }
  &:active {
  }
`;

export const ButtonBottom = styled.button`
  position: absolute;
  top: auto;
  bottom: auto;
  left: 70px;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 40%;

  path {
    fill: #203b54;
  }

  rect {
    stroke: #dbe0e9;
  }

  &:hover {
    path {
      fill: #d95a45;
    }
    &:active {
      rect {
        stroke: #d95a45;
      }
      transition: all 2s;
    }
    transition: all 2s;
  }
  transition: all 2s;
`;

export const AddAttributeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px;
  height: 40px;
  border: 1px solid #d95a45;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;

  img {
    margin-right: 16px;
  }
`;

export const Predefined = styled.span`
  margin-right: 80px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #7a8fa3;
`;
