import styled from "styled-components";
import { MenuProvider } from "react-contexify";
import { MenuItem } from "@szhsin/react-menu";

export const Container = styled.div`
  position: relative;
  padding-bottom: 4px;
  margin: 20px;
  width: 290px;
  height: min-content;
  min-height: 267px;
  //max-height: 310px;
  background-color: ${({ status }) => {
    if (status === "new") return "#FBE7DC";
    if (status === "inprogress") return "#FBF5D0";
    return "#E1FBDC";
  }};
  border-radius: 12px;
  color: #203b54;

  box-shadow: ${({ selected }) =>
    selected && "0px 10px 44px rgba(108, 126, 147, 0.15)"};

  cursor: pointer;
`;

export const Card = styled(MenuProvider)`
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  height: 46px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Text = styled.p`
  height: 10px;
  margin: 14px 0 20px 20px;
  font-weight: 400;
  font-size: 14px;
`;

export const TimeContainer = styled.div`
  padding: 16px 0 0 20px;
  display: flex;

  background-color: ${({ status }) => {
    if (status === "new") return "#FADDCD";
    if (status === "inprogress") return "#F9F2C2";
    return "#CCF9C4";
  }};

  height: 72px;
  div {
    width: 122px;
    margin-right: 14px;
    display: flex;
    flex-direction: column;
  }

  > div:last-of-type {
    margin-right: 0px;
  }
  span {
    font-weight: 400;
    font-size: 13px;
  }
`;

export const TimeText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  span {
    font-weight: 600;
    font-size: 14px;
    margin-left: 20px;
  }
`;

export const ValueText = styled.p`
  margin: 10px 0 10px 20px;
  font-weight: 300;
  font-size: 13px;
  color: #7a8fa3;
`;

export const Line = styled.div`
  margin-left: 20px;
  margin-bottom: 4px;
  width: 250px;
  height: 0;
  border: ${({ status }) => {
    if (status === "new") return "1px solid #FADDCD";
    if (status === "inprogress") return "1px solid #F9F2C2";
    return "1px solid #CCF9C4";
  }};
`;

export const CirclesContainer = styled.div`
  display: flex;
  margin: 0 20px;
  padding: 2.5px 0;
  height: 35px;

  img {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    align-self: center;
  }
`;

export const ProcessCircle = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #dee2ea;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
`;

export const MenuProcessCircle = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ open }) => (open ? "#DEE2EA" : "#ffffff")};
  border: 1px solid #dee2ea;
  box-sizing: border-box;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
  cursor: pointer;
`;

export const DeleteMenuItem = styled(MenuItem)`
  color: #d95a45;
`;

export const AddConnectionButton = styled.button`
  background-color: transparent;
  border: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;

  &:hover {
    path {
      fill: #fff;
    }
    circle {
      fill: #203b54;
    }
  }
`;

export const Stop = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -14px;
  top: 40px;
  background: #ff5555;
  border: 1px solid #dee2ea;
  border-radius: 50px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    transform: translateY(-5%);
  }
`;

export const Avatar = styled.img`
  position: absolute;
  top: -14px;
  right: -14px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
`;

export const Number = styled.div`
  position: absolute;
  top: -14px;
  left: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  background-color: #fff;
  border: 1px solid #dee2ea;
  display: flex;
  justify-content: center;
  align-items: center;
`;
