import styled from "styled-components";
import { MenuProvider, Menu, Item } from "react-contexify";
import arrowTop from "../../assets/arrowTop.svg";
import arrowBottom from "../../assets/arrowBottom.svg";
import { MainLoader } from "../../components/UI/Loaders/MainLoader";

export const Container = styled.div`
  height: calc(100vh - 174px);
`;

export const CatalogLoader = styled(MainLoader)`
  position: absolute;
  top: 50vh;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;

export const TopBar = styled.div`
  height: 48px;
  display: flex;
  padding-left: 45px;
  border-bottom: 1px solid #cbd0dc;

  transition: all 0.2s;
`;

export const TopBarButton = styled.button`
  background-color: #fff;
  display: flex;
  align-items: end;
  cursor: pointer;
  font-size: 16px;
  margin-right: 28px;
  color: #4d6276;
  padding: 0 4px 6px;
  border: none;
  border-bottom: ${({ active }) =>
    active ? "2px solid #d95a45" : "2px solid transparent"};

  transition: border 0.2s;
`;

export const MainData = styled(MenuProvider)`
  padding: 28px 40px 0;

  // height: calc(100vh - 443px);
  //height: 100%;
  // border-bottom: 1px solid #cbd0dc;

  height: ${({ isSelected }) => (isSelected ? "calc(100vh - 443px)" : "100%")};

  background-color: #fafcfe;
  overflow-y: scroll;

  display: grid;
  grid-template-columns: repeat(auto-fill, 177px);
  grid-template-rows: repeat(auto-fill, 130px);
  grid-gap: 20px;
  font-size: 14px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;

export const ListItemText = styled.span`
  margin-left: 10px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
`;

export const Details = styled.div`
  width: 100%;
  height: 269px;
  padding: 26px 40px;
  border-top: 1px solid #cbd0dc;

  > div {
    margin-bottom: 20px;
  }

  span {
    font-weight: 300;
    font-size: 13px;
    color: #7a8fa3;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #203b54;
    margin: 0;
  }
`;

export const Name = styled.div`
  span {
    height: 18px;
    margin-bottom: 5px;
  }
  p {
    height: 20px;
  }
`;

export const Author = styled.div`
  span {
    height: 18px;
  }
  p {
    height: 26px;
  }
`;

export const Commentary = styled.div`
  p {
    width: 70%;
  }
`;

export const StyledLi = styled(MenuProvider)`
  > div {
    background: ${({ selected }) => (selected ? "#E6E9EF" : "none")};
    border-top: ${({ selected }) => (selected ? "1px solid #cbd0dc" : "none")};
    border-bottom: ${({ selected }) =>
      selected ? "1px solid #cbd0dc" : "none"};
    padding: ${({ selected }) =>
      selected ? "9px 20px 9px 40px" : "10px 20px 10px 40px"};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledContextMenu = styled(Menu)`
  position: fixed;
  padding: 5px 0;
  background: #fefefe;
  border: 1px solid #e7eaf0;
  box-sizing: border-box;
  box-shadow: -2px 4px 8px rgba(113, 128, 158, 0.25);
`;

export const StyledMenuItem = styled(Item)`
  padding: 10px 22px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #203b54;
  cursor: pointer;

  &:hover {
    background: #e7eaf0;
  }
`;

export const StyledLI = styled.li`
  background: ${({ selected }) => (selected ? "#E6E9EF" : "none")};
  border-top: ${({ selected }) => (selected ? "1px solid #cbd0dc" : "none")};
  border-bottom: ${({ selected }) => (selected ? "1px solid #cbd0dc" : "none")};
  padding: ${({ selected }) =>
    selected ? "9px 20px 9px 40px" : "10px 20px 10px 40px"};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;

  img:first-child {
    width: 19px;
    height: 19px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 19px;
    height: 19px;
  }
`;

export const Avatar = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 15px;
  border-radius: 50px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 5px;
`;

export const Breadcrumbs = styled.div`
  width: 100%;
  height: 42px;
  background: #eff3f9;
  border-top: 1px solid #cbd0dc;
  border-bottom: 1px solid #cbd0dc;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 13px;

  a {
    text-decoration: none;
    color: #203b54;
  }
`;
