import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import {
  Card,
  Title,
  Text,
  TimeContainer,
  TimeText,
  ValueContainer,
  ValueText,
  Line,
  CirclesContainer,
  ProcessCircle,
  Stop,
  Avatar,
  Number,
  MenuProcessCircle,
  DeleteMenuItem,
  AddConnectionButton,
  Container,
} from "./styles";

export const CardComponent = ({
  onClick,
  item,
  selectedId,
  onContextMenu,
  handleAddRelation,
  handleDeleteRelation,
}) => {
  const formatNumber = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const formatTime = (hours) => {
    if (hours / 24 < 1) return `${hours} ч`;
    const days = Math.floor(hours / 24);
    const remainder = hours % 24;
    const remHours = Math.floor(remainder);
    return `${days} д ${remHours} ч`;
  };

  return (
    <Container
      onClick={onClick}
      status={item.status}
      selected={selectedId === item.id}
    >
      <Card onContextMenu={onContextMenu} id="processMenu" data={item}>
        <Number>{item.number}</Number>
        <Avatar src={item.avatar || "/noAvatar.svg"} />
        <Title>{item?.name}</Title>
        <Text>{item?.performer_role?.name}</Text>
        <TimeContainer status={item.status}>
          <div>
            <span>Time max</span>
            <TimeText>{formatTime(item.t_max)}</TimeText>
          </div>
          <div>
            <span>Time norm</span>
            <TimeText>{formatTime(item.t_norm)}</TimeText>
          </div>
        </TimeContainer>
        <ValueContainer>
          <ValueText>Стоимость</ValueText>
          <span>{formatNumber(item.cost)} руб.</span>
        </ValueContainer>
        <Line status={item.status} />
        {(item?.bind_in.length > 0 || item?.bind_out.length > 0) && (
          <>
            {item?.bind_in.length > 0 && (
              <CirclesContainer>
                <img src="/arrowRightIcon.svg" alt="" />
                {item?.bind_in?.map((i) => (
                  <ProcessCircle key={i.id}>{i.number}</ProcessCircle>
                ))}
              </CirclesContainer>
            )}
            {item?.bind_out.length > 0 && (
              <CirclesContainer>
                <img src="/arrowLeftIcon.svg" alt="" />
                {item?.bind_out?.map((i) => (
                  <Menu
                    key={i.id}
                    menuButton={({ open }) => (
                      <MenuProcessCircle open={open}>
                        {i.number}
                      </MenuProcessCircle>
                    )}
                    transition
                  >
                    <MenuItem>
                      {i.number}. {i.name}
                    </MenuItem>
                    <DeleteMenuItem
                      onClick={() => handleDeleteRelation(item.id, i.id)}
                    >
                      <img src="/deleteAttributeValueIcon.svg" alt="delete" />
                      Удалить связь
                    </DeleteMenuItem>
                  </Menu>
                ))}
              </CirclesContainer>
            )}
          </>
        )}
        <AddConnectionButton
          onClick={handleAddRelation}
          src="/addRelationIcon.svg"
          alt="add"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.5" cy="9.5" r="9" stroke="#203B54" />
            <path
              d="M13.151 9.80921H10.169V12.8332H8.97897V9.80921H6.01097V8.73121H8.97897V5.69321H10.169V8.73121H13.151V9.80921Z"
              fill="#203B54"
            />
          </svg>
        </AddConnectionButton>
        {item.can_stop && (
          <Stop>
            <span>stop</span>
          </Stop>
        )}
      </Card>
    </Container>
  );
};
