import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  width: 124px;
  height: 46px;
  border-radius: 10px;

  font-family: Noto sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.0041em;

  background: ${({ outlined }) => (outlined ? "transparent" : "#d95a45")};
  border: ${({ outlined }) => (outlined ? "1px solid #D95A45" : "none")};
  color: ${({ outlined }) => (outlined ? "#d95a45" : "#FBFBFB")};
  cursor: pointer;

  &:hover {
    background: ${({ outlined }) => outlined && "#d95a45"};
    color: ${({ outlined }) => outlined && "#FBFBFB"};
  }

  // linear gradient animation
  position: relative;
  z-index: 1; /* matters! */
  overflow: hidden;

  ${({ outlined }) =>
    !outlined &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(
          90.16deg,
          #522d75 0.18%,
          #c84046 27.64%,
          #d95a45 99.9%
        );
        transition: transform 0.4s;
        z-index: -1;
        transform: translateX(-100%);
      }
      &:hover::before {
        transform: translateX(0%);
      }
      &:active::before {
        transform: translateX(-100%);
        background: #b33c4e;
      }
    `}

  &:active {
    background: #b33c4e;
  }

  transition: all 0.2s;
`;
