import React, { useState, useEffect } from "react";

import { Link, useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import {
  AddStageButton,
  Container,
  Footer,
  Header,
  Main,
  Navigator,
  NavItem,
  NavIconContainer,
  NavStart,
  NavStop,
  ProgressBar,
  Breadcrumbs,
  ExcelDownload,
  StagesLoader,
} from "./styles";

import { StageCard } from "../../components/StageCard";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl, getSubdomainName } from "../../api";
import { AddStageModal } from "../../components/UI/Modals/AddStageModal";
import { CreateOrderModal } from "../../components/UI/Modals/CreateOrderModal";
import { EditStageModal } from "../../components/UI/Modals/EditStageModal";
import { ConfirmDeleteModal } from "../../components/UI/Modals/ConfirmDeleteModal";
import { AddRelationModal } from "../../components/UI/Modals/AddRelationModal";
import { AttributeModal } from "../../components/UI/Modals/AddAttributeModal";

export const StagesPage = ({ firstStage }) => {
  const { token } = useAuth();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { state: indexFromLocation } = useLocation();

  const [isCreateStageModalShown, setIsCreateStageModalShown] = useState(false);
  const [isEditStageModalShown, setIsEditStageModalShown] = useState(false);
  const [stageToEdit, setStageToEdit] = useState(null);
  const [stageToDeleteId, setStageToDeleteId] = useState(null);
  const [relationToDeleteId, setRelationToDeleteId] = useState(null);
  const [isCreateOrderModalShown, setIsCreateOrderModalShown] = useState(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isDeleteRelationModalShown, setIsDeleteRelationModalShown] =
    useState(false);
  const [isAddRelationModalShown, setIsAddRelationModalShown] = useState(false);
  const [stageToCreateRelationId, setStageToCreateRelationId] = useState(null);
  const [isAddAttributeModalShown, setIsAddAttributeModalShown] =
    useState(false);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery(["editProcess", id], async () =>
    axios.get(`${baseUrl}/catalog/processes/edit/${id}`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const createMutation = useMutation(
    "addStage",
    async ({ name, number }) => {
      await axios.post(
        `${baseUrl}/catalog/processes/${id}/stages`,
        {
          name,
          number,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("editProcess");
        setIsCreateStageModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editStageName",
    async ({ name, number, stageId }) => {
      await axios.put(
        `${baseUrl}/catalog/processes/${id}/stages/${stageId}`,
        {
          name,
          number,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsEditStageModalShown(false);
        queryClient.invalidateQueries("editProcess");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteStage",
    async (stageId) => {
      await axios.delete(
        `${baseUrl}/catalog/processes/${id}/stages/${stageId}`,
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsDeleteModalShown(false);
        setStageToDeleteId(null);
        queryClient.invalidateQueries("editProcess");
      },
    }
  );

  const createRelationMutation = useMutation(
    "addRelation",
    async ({ stageId, relationId }) => {
      await axios.post(
        `${baseUrl}/catalog/stage_relations`,
        {
          stage_id: stageId,
          relation_id: relationId,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", id]);
        setIsAddRelationModalShown(false);
      },
    }
  );

  const deleteRelationMutation = useMutation(
    "deleteRelation",
    async ({ stageId, relationId }) => {
      await axios.delete(`${baseUrl}/catalog/stage_relations`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
        data: {
          stage_id: stageId,
          relation_id: relationId,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", id]);
        setIsDeleteRelationModalShown(false);
      },
    }
  );

  const createAttribute = useMutation(
    "createAttribute",
    async (data) => {
      await axios.post(`${baseUrl}/catalog/attributes`, data, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", id]);
        setIsAddAttributeModalShown(false);
      },
    }
  );

  const handleCreateAttribute = (data) => {
    createAttribute.mutate(data);
  };

  const handleCreateRelation = (data) => {
    createRelationMutation.mutate(data);
  };

  const handleDeleteRelation = (data) => {
    deleteRelationMutation.mutate(data);
  };

  const stagesOnScreen = Math.floor(window.innerWidth / 348);
  const stagesAmount = response?.data?.data?.process?.stages.length;

  const stagesAmountToUse =
    stagesAmount < stagesOnScreen ? stagesAmount : stagesOnScreen;

  const coordsFromLS = localStorage.getItem("coords");

  const [coords, setCoords] = useState([]);

  useEffect(() => localStorage.removeItem("coords"), [stagesOnScreen]);

  function calculateInitialCoords() {
    if (indexFromLocation) {
      if (indexFromLocation > stagesAmount - stagesAmountToUse) {
        return [stagesAmount - stagesAmountToUse, stagesAmount];
      }
      return [indexFromLocation - 1, indexFromLocation - 1 + stagesAmountToUse];
    }
    if (JSON.parse(coordsFromLS)) {
      return JSON.parse(coordsFromLS);
    }
    return [firstStage || 0, stagesAmountToUse];
  }

  useEffect(() => {
    setCoords(calculateInitialCoords());
  }, [response]);

  const handleStartClick = () => {
    setCoords([0, stagesAmountToUse]);
    localStorage.setItem("coords", JSON.stringify([0, stagesAmountToUse]));
  };

  const handleFinishClick = () => {
    const newCoords = [stagesAmount - stagesAmountToUse, stagesAmount];
    setCoords(newCoords);
    localStorage.setItem(
      "coords",
      JSON.stringify([0, stagesAmount - stagesAmountToUse])
    );
  };

  const handleNavClick = (index) => {
    if (index > stagesAmount - stagesAmountToUse) {
      setCoords([stagesAmount - stagesAmountToUse, stagesAmount]);
      localStorage.setItem(
        "coords",
        JSON.stringify([stagesAmount - stagesAmountToUse, stagesAmount])
      );
      return;
    }

    setCoords([index, index + stagesAmountToUse]);
    localStorage.setItem(
      "coords",
      JSON.stringify([index, index + stagesAmountToUse])
    );
  };

  const handlePrevClick = () => {
    if (coords[0] > 0) {
      setCoords([coords[0] - 1, coords[1] - 1]);
      localStorage.setItem(
        "coords",
        JSON.stringify([coords[0] - 1, coords[1] - 1])
      );
    }
  };

  const handleNextClick = () => {
    if (
      coords[1] - 1 <
      // eslint-disable-next-line no-unsafe-optional-chaining
      response?.data?.data?.process?.stages.length - 1
    ) {
      setCoords([coords[0] + 1, coords[1] + 1]);
      localStorage.setItem(
        "coords",
        JSON.stringify([coords[0] + 1, coords[1] + 1])
      );
    }
  };

  const handleCreateStage = (data) => {
    createMutation.mutate(data);
    setIsCreateStageModalShown(false);
  };

  const handleEditStage = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (data) => {
    deleteMutation.mutate(data);
  };

  const handleDownloadExcel = () => {
    axios
      .get(`${baseUrl}/catalog/processes/${id}/excel`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((resp) => {
        const filename = resp.headers["content-disposition"]
          .split("filename=")[1]
          .split('"')[1];
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", decodeURI(filename));
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  if (isLoading) return <StagesLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <Container>
      <Breadcrumbs>
        <Link
          to={`/view/${id}`}
          onClick={() => localStorage.removeItem("coords")}
        >
          <img src="/backButtonIcon.svg" alt="back" />
          Назад
        </Link>
        <span>{response.data.data.process.name}</span>
      </Breadcrumbs>

      <Header>
        <ProgressBar>
          <div>
            Прогресс работы
            <span>
              {response?.data?.data?.process?.stages_ready}/
              {response?.data?.data?.process?.stages_total}
            </span>
          </div>

          <progress
            value={response?.data?.data?.process?.stages_ready}
            max={response?.data?.data?.process?.stages_total}
          />
        </ProgressBar>
        <AddStageButton onClick={() => setIsCreateStageModalShown(true)}>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.55826 4.768H5.15026V8.224H3.79026V4.768H0.398262V3.536H3.79026V0.0639992H5.15026V3.536H8.55826V4.768Z"
              fill="#D95A45"
            />
          </svg>
          Добавить этап
        </AddStageButton>

        <ExcelDownload onClick={handleDownloadExcel}>
          <img src="/excelIcon.svg" alt="excel" />
          <span>Выгрузить в Excel</span>
        </ExcelDownload>
      </Header>

      <Main>
        <NavIconContainer onClick={handlePrevClick}>
          <img width="25px" src="/stepLeftIcon.svg" alt="" />
        </NavIconContainer>
        {response?.data?.data?.process?.stages
          .slice(coords[0], coords[1])
          .map((stage, index) => (
            <StageCard
              handleAddAttribute={() => setIsAddAttributeModalShown(true)}
              handleAddRelation={() => {
                setStageToCreateRelationId(stage.id);
                setIsAddRelationModalShown(true);
              }}
              key={stage.id}
              item={stage}
              index={index + 1}
              onEditStage={() => {
                setStageToEdit(stage);
                setIsEditStageModalShown(true);
              }}
              onDelete={() => {
                setStageToDeleteId(stage.id);
                setIsDeleteModalShown(true);
              }}
              onRelationDelete={(stageId, relationId) => {
                setRelationToDeleteId({ stageId, relationId });
                setIsDeleteRelationModalShown(true);
              }}
            />
          ))}
        <NavIconContainer onClick={handleNextClick}>
          <img width="25px" src="/stepRightIcon.svg" alt="" />
        </NavIconContainer>
      </Main>

      <Footer>
        <Navigator>
          <NavStart onClick={handleStartClick}>start</NavStart>
          {response?.data?.data?.process?.stages.map((stage, index) => (
            <NavItem
              key={index}
              status={stage.status}
              isShown={index >= coords[0] && index < coords[1]}
              onClick={() => handleNavClick(index)}
            />
          ))}
          <NavStop onClick={handleFinishClick}>finish</NavStop>
        </Navigator>
      </Footer>

      {isCreateStageModalShown && (
        <AddStageModal
          onCreate={handleCreateStage}
          onClose={() => setIsCreateStageModalShown(false)}
        />
      )}
      {isEditStageModalShown && (
        <EditStageModal
          onEdit={handleEditStage}
          stage={stageToEdit}
          onClose={() => setIsEditStageModalShown(false)}
        />
      )}
      {isCreateOrderModalShown && (
        <CreateOrderModal onClose={() => setIsCreateOrderModalShown(false)} />
      )}

      {isDeleteModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteMutation.isLoading}
          onDelete={() => handleDelete(stageToDeleteId)}
          onClose={() => setIsDeleteModalShown(false)}
          heading="этап"
        />
      )}

      {isDeleteRelationModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteRelationMutation.isLoading}
          onDelete={() => handleDeleteRelation(relationToDeleteId)}
          onClose={() => setIsDeleteRelationModalShown(false)}
          heading="связь"
        />
      )}

      {isAddRelationModalShown && (
        <AddRelationModal
          stageId={stageToCreateRelationId}
          createRelation={handleCreateRelation}
          processId={id}
          isLoading={createRelationMutation.isLoading}
          onClose={() => setIsAddRelationModalShown(false)}
        />
      )}

      {isAddAttributeModalShown && (
        <AttributeModal
          handleCreateAttribute={handleCreateAttribute}
          onClose={() => setIsAddAttributeModalShown(false)}
        />
      )}
    </Container>
  );
};
