import React, { useState } from "react";

import {
  CloseButton,
  Form,
  Header,
  Input,
  Label,
  SubmitButton,
  Textarea,
} from "./styles";
import { Loader } from "../../Loaders";
import { Modal } from "../Modal";

export const CreateOrderModal = ({ isLoading, onClose }) => {
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form onSubmit={(e) => console.log(e)}>
        <Header>Создание регламента</Header>
        <Label>
          Наименование
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <Label>
          Комментарий
          <Textarea
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : "Создать"}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
