import styled from "styled-components";

import { Button } from "../../components/UI/Buttons";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100vh;
`;

export const ImageContainer = styled.div`
  width: 50%;
  img {
    width: 100%;
    height: 100vh;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8%;
  width: 60%;

  span {
    font-size: 36px;
    font-weight: 600;
  }
  div {
    width: 430px;
  }

  form {
    span {
      color: red;
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

export const LogoMantrix = styled.img.attrs({
  src: "/logo.svg",
})`
  width: 187px;
  height: 40px;
`;

export const WelcomeText = styled.p`
  color: #364f65;
  margin-top: 160px;
  font-weight: 100;
`;

export const Entrance = styled.div`
  margin-bottom: 40px;
`;

export const Text = styled.p`
  font-weight: 400;
  color: #364f65;
  display: flex;
  justify-content: space-between;
`;

export const LoginInput = styled.input`
  width: 430px;
  height: 45px;
  color: #798998;
  border: 1px solid #e7edef;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 20px;
  font-size: 14px;
`;

export const ForgotPasswordText = styled.p`
  text-align: right;

  a {
    color: #d95a45;
    text-decoration: none;
  }
`;

export const LoginButton = styled(Button)``;

export const ErrorMessage = styled.div`
  margin-top: 36px;
  padding: 14px 23px;
  background: #fff7f7;

  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0041em;
  color: #000000;
`;
