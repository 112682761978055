import styled from "styled-components";
import Select from "react-select";

import { Button } from "../../Buttons";
import arrowTop from "../../../../assets/arrowTop.svg";
import arrowBottom from "../../../../assets/arrowBottom.svg";

export const Container = styled.div`
  position: absolute;
  top: ${({ isProcess }) => (isProcess ? "5vh" : "calc(50% - 200px)")};
  left: calc(50% - 306px);
  width: 613px;
  z-index: 100;
  background: #f8fafc;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;

export const Header = styled.h2`
  align-self: center;
`;

export const Form = styled.form`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.img`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  align-self: flex-end;
  margin: 12px 12px 0 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #7a8fa3;
`;

export const SelectElement = styled(Select)`
  .react-select-container {
    font-family: Noto Sans, sans-serif;
  }

  .react-select__menu {
    z-index: 2;
  }

  .react-select__value-container {
    background-color: red;
    font-family: Noto Sans, sans-serif;
  }

  .react-select__control {
    padding-left: 12px;
    box-shadow: none;
    color: #203b54 !important;
  }

  .react-select__option {
    &:hover {
      background-color: #eeeeee;
      border: 1px solid #e8ecef;
      font-family: Noto Sans, sans-serif;
    }

    &--is-selected {
      color: #203b54;
      background-color: #eeeeee;

      &:hover {
        color: black;
      }
    }

    &--is-focused {
      background-color: transparent;
    }
  }
`;

export const Input = styled.input`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 40px;
  font-family: Noto sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  outline: none;
  padding-left: 22px;
`;

export const Textarea = styled.textarea`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 80px;
  font-family: Noto sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  outline: none;
  padding: 9px 22px;
  resize: none;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  letter-spacing: 0.0041em;
`;
