import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import {
  Breadcrumbs,
  Commentary,
  Container,
  Details,
  Main,
  MainInner,
  Name,
} from "./styles";
import { FormCard } from "./FormCard";
import { Author, Avatar } from "../../pages/CatalogPage/styles";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl, getSubdomainName } from "../../api";
import { ConfirmDeleteModal } from "../UI/Modals/ConfirmDeleteModal";
import { FormModal } from "../UI/Modals/FormModal";
import { DirectoriesAddButton } from "../UI/DirectoriesAddButton";
import { DirectoriesLoader } from "../../pages/DirectoryPage/styles";

export const Forms = () => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  const [formToDeleteId, setFormToDeleteId] = useState(null);
  const [formToEdit, setFormToEdit] = useState(null);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isFormModalShown, setIsFormModalShown] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery("getAllForms", async () =>
    axios.get(`${baseUrl}/catalog/forms`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const createMutation = useMutation(
    "addForm",
    async ({ name, comment }) => {
      await axios.post(
        `${baseUrl}/catalog/forms`,
        {
          name,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllForms");
        setIsFormModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editForm",
    async ({ name, comment }) => {
      await axios.put(
        `${baseUrl}/catalog/forms/${formToEdit.id}`,
        {
          name,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsFormModalShown(false);
        queryClient.invalidateQueries("getAllForms");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteForm",
    async (id) => {
      await axios.delete(`${baseUrl}/catalog/forms/${id}`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        setIsFormModalShown(false);
        queryClient.invalidateQueries("getAllForms");
      },
    }
  );

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
    setIsDeleteModalShown(false);
  };

  if (isLoading) return <DirectoriesLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <Container>
      <Breadcrumbs>
        <div>
          <span>Справочники</span>
          <img
            style={{ margin: "0 10px" }}
            src="/breadCrumbArrow.svg"
            alt="arrow"
          />
        </div>
        <div>
          <span>Формы</span>
        </div>
      </Breadcrumbs>

      <Main
        isItemSelected={!!selectedForm}
        onClick={() => setSelectedForm(false)}
      >
        <DirectoriesAddButton
          onClick={() => {
            setFormToEdit(null);
            setIsFormModalShown(true);
          }}
        >
          форму
        </DirectoriesAddButton>

        <MainInner isItemSelected={!!selectedForm}>
          {response?.data?.data?.forms.map((form, index) => (
            <FormCard
              isSelected={selectedForm?.id === form.id}
              key={form.id}
              form={form}
              index={index + 1}
              onEdit={(e) => {
                e.stopPropagation();
                setFormToEdit(form);
                setIsFormModalShown(true);
              }}
              onDelete={(e) => {
                e.stopPropagation();
                setFormToDeleteId(form.id);
                setIsDeleteModalShown(true);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedForm(form);
              }}
            />
          ))}
        </MainInner>
      </Main>

      {selectedForm && (
        <Details>
          <div>
            <Name>
              <span>Наименование программы</span>
              <p>{selectedForm ? selectedForm.name : " "}</p>
            </Name>

            <Author>
              <span>Автор</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>{selectedForm ? selectedForm?.author?.name : " "}</p>
                {selectedForm?.author?.photo && (
                  <Avatar src={selectedForm?.author?.photo} alt="pic" />
                )}
              </div>
            </Author>
          </div>

          <Commentary>
            <span>Комментарий</span>
            <p>{selectedForm ? selectedForm.comment : " "}</p>
          </Commentary>
        </Details>
      )}

      {isFormModalShown && (
        <FormModal
          isEdit={!!formToEdit}
          form={formToEdit}
          isLoading={createMutation.isLoading || editMutation.isLoading}
          onCreate={handleCreate}
          onEdit={handleEdit}
          onClose={() => setIsFormModalShown(false)}
        />
      )}

      {isDeleteModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteMutation.isLoading}
          id={formToDeleteId}
          onDelete={handleDelete}
          onClose={() => setIsDeleteModalShown(false)}
          heading="форму"
        />
      )}
    </Container>
  );
};
