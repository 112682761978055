import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import {
  Form,
  CloseButton,
  Label,
  Header,
  SubmitButton,
  SelectElement,
  StyledModal,
} from "./styles";
import { Loader } from "../../Loaders";
import { baseUrl, getSubdomainName } from "../../../../api";
import { useAuth } from "../../../../hooks/useAuth";

export const AddRelationModal = ({
  processId,
  stageId,
  isLoading,
  createRelation,
  onClose,
}) => {
  const { token } = useAuth();
  const [relationId, setRelationId] = useState();

  const {
    isLoading: isFoldersLoading,
    isError,
    error,
    data: response,
  } = useQuery("getAllRelations", async () =>
    axios.get(`${baseUrl}/catalog/processes/${processId}/stages`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const options =
    response &&
    response.data.data.stages.map((stage) => ({
      value: stage.id,
      label: `${stage.number}. ${stage.name}`,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    createRelation({ stageId, relationId });
  };

  if (isError) return <div>{error.message}</div>;

  return (
    <StyledModal maxWidth="454px">
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Header>Добавить связь</Header>
        <div>
          {isFoldersLoading ? (
            "Loading..."
          ) : (
            <Label>
              <span>Переход на этап</span>
              <SelectElement
                classNamePrefix="react-select"
                placeholder="Выберите этап..."
                onChange={(i) => setRelationId(i.value)}
                options={options}
              />
            </Label>
          )}
        </div>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : "Создать"}
        </SubmitButton>
      </Form>
    </StyledModal>
  );
};
