import React from "react";

import { StyledButton } from "./styles";

export const Button = ({ outlined, disabled, children, ...rest }) => {
  return (
    <StyledButton outlined={outlined} disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
};
