import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: ${({ isSelected }) => isSelected && "#E8ECEF"};
  border: 1px solid #dbe0e9;
  border-radius: 7px;
  margin-bottom: 5px;
  padding: 14px 12px 14px 18px;
  cursor: pointer;

  &:hover {
    background-color: #f2f5f7;
    background-color: ${({ isSelected }) => isSelected && "#E8ECEF"};
  }

  transition: background-color 0.2s;

  > div {
    display: flex;
  }
`;

export const EditButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  text-decoration-line: underline;
  color: #d95a45;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;

  img {
    margin-right: 12px;
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Number = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #a6b1bb;
  margin-right: 5px;
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
`;

export const HourlyCost = styled.div`
  margin-right: 74px;
  width: 220px;
  > span {
    &:first-of-type {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      letter-spacing: 0.0041em;
      color: #7a8fa3;
    }

    &:last-of-type {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.0041em;
      color: #203b54;
      margin-left: 14px;
    }
  }
`;
