import React from "react";
import { Container } from "./styles";

export const MainLoader = ({ className }) => {
  return (
    <Container className={className}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
};
