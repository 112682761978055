import React, { useState } from "react";

import { Loader } from "../../Loaders";

import {
  CloseButton,
  Form,
  Input,
  Label,
  SubmitButton,
  Header,
} from "./styles";
import { Modal } from "../Modal";

export const EditStageModal = ({ onEdit, isLoading, onClose, stage }) => {
  const [number, setNumber] = useState(stage.number);
  const [name, setName] = useState(stage.name);

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onEdit({ name, number, stageId: stage.id });
        }}
      >
        <Header>Изменить этап</Header>
        <Label>
          Номер этапа
          <Input
            type="text"
            height={40}
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </Label>

        <Label>
          Название этапа
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : "Сохранить"}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
