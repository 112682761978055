import styled from "styled-components";
import { MenuProvider } from "react-contexify";

export const Container = styled(MenuProvider)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 10px 6px;
  width: 177px;
  cursor: pointer;

  user-select: none;

  &:hover {
    background-color: #f2f5f7;
    background-color: ${({ selected }) => selected && "#E8ECEF"};
  }

  background-color: ${({ selected }) => selected && "#E8ECEF"};

  span {
    max-width: 100%;
    height: 40px;

    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const Avatar = styled.img`
  position: absolute;
  top: -6px;
  right: -18px;
  width: 32px;
  height: 32px;
  border-radius: 50px;
`;

export const Star = styled.div`
  position: absolute;
  top: 18px;
  left: 10px;
  width: 19px;
  height: 19px;

  &:hover {
    path {
      stroke: #57718a;
    }
  }
`;

export const Folder = styled.img`
  position: relative;
  width: 66px;
  height: 52px;
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 16px;
`;

// export const StyledContextMenu = styled(Menu)`
//   position: fixed;
//   padding: 5px 0;
//   background: #fefefe;
//   border: 1px solid #e7eaf0;
//   box-sizing: border-box;
//   box-shadow: -2px 4px 8px rgba(113, 128, 158, 0.25);
// `;
//
// export const StyledMenuItem = styled(Item)`
//   padding: 10px 22px 16px;
//   font-weight: 400;
//   font-size: 14px;
//   color: #203b54;
//
//   &:hover {
//     background: #e7eaf0;
//   }
// `;
