import styled from "styled-components";

import arrowTop from "../../assets/arrowTop.svg";
import arrowBottom from "../../assets/arrowBottom.svg";
import { MainLoader } from "../../components/UI/Loaders/MainLoader";

export const Breadcrumbs = styled.div`
  width: 100%;
  height: 42px;
  background: #eff3f9;
  border-top: 1px solid #cbd0dc;
  border-bottom: 1px solid #cbd0dc;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 13px;

  span {
    font-family: Noto sans, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  a {
    margin-right: 40px;
    font-family: Noto sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: 0.0041em;
    text-decoration: none;
    color: #d95a45;
  }

  img {
    margin-right: 6px;
  }
`;

export const Container = styled.div`
  height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 16px 40px;
`;

export const ExcelDownload = styled.div`
  position: absolute;
  top: -14px;
  right: 16px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  span {
    font-family: Noto sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    text-decoration-line: underline;
    color: #203b54;
  }
`;

export const Main = styled.main`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  height: calc(100vh - 200px);
  // width: 100%;

  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }

  > div:not(:last-of-type) {
    margin-right: 52px;
  }

  > img {
    width: 30px;
    height: 30px;
    align-self: center;
  }
`;

export const Footer = styled.footer`
  margin-top: auto;
`;

export const Navigator = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;

  li {
    cursor: pointer;
  }
`;

export const NavStart = styled.li`
  width: 55.08px;
  height: 27px;
  background: #68db7a;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavItem = styled.li`
  margin: 0 3px;
  width: 38px;
  height: ${({ isShown }) => (isShown ? "20px" : "8px")};
  background-color: ${({ status }) => {
    if (status === "new") return "#FE8585";
    if (status === "inprogress") return "#fdef94";
    return "#98EF89";
  }};
  border-radius: ${({ isShown }) => (isShown ? "10px" : "4px")};

  transition: 0.2s;
`;

export const NavStop = styled.li`
  width: 55.08px;
  height: 27px;
  background: #68db7a;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddStageButton = styled.button`
  width: 176px;
  height: 46px;
  border: 1px solid #d95a45;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  cursor: pointer;

  &:hover {
    background-color: #d95a45;
    color: #fff;

    path {
      fill: #fff;
    }
  }

  svg {
    margin-right: 12px;
  }

  transition: all 0.2s;
`;

export const ProgressBar = styled.div`
  margin-bottom: 5px;
  position: absolute;
  top: 0;
  left: 0;

  div {
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  span {
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  progress {
    width: 341px;
    height: 7px;
    border-radius: 10px;
    border: 0 solid #dde9f2;
    &::-webkit-progress-value {
      background: linear-gradient(89.91deg, #522d75 1.79%, #c84046 95.83%);
      border-radius: 10px;
    }
    &::-webkit-progress-bar {
      background: #dde9f2;
      border-radius: 10px;
    }

    transition: all 0.2s ease-in-out;
  }
`;

export const NavIconContainer = styled.div`
  position: sticky;
  top: 0;
  height: 697px;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  cursor: pointer;
`;

export const StagesLoader = styled(MainLoader)`
  position: absolute;
  top: 50vh;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;
