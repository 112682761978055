import React from "react";

import { Container, Avatar, Star, Folder, InnerContainer } from "./styles";

const CatalogItem = ({ item, selected, onClick }) => {
  const {
    is_folder: isFolder,
    is_favourite: isFavourite,
    name,
    author: { photo },
  } = item;

  return (
    <Container
      data={item}
      id="secondary"
      selected={selected}
      onClick={onClick}
      storeRef={false}
    >
      <Star>
        {isFavourite ? (
          <svg
            id="star"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="star"
              d="M8.03146 0.898974C8.19313 0.465062 8.80687 0.465061 8.96854 0.898973L10.7238 5.61024C10.794 5.79864 10.9703 5.92668 11.1711 5.93522L16.1942 6.14876C16.6569 6.16843 16.8465 6.75213 16.4838 7.03997L12.5456 10.1652C12.3881 10.2902 12.3208 10.4974 12.3747 10.6911L13.7238 15.5343C13.8481 15.9804 13.3516 16.3411 12.9657 16.0851L8.77645 13.3054C8.60891 13.1942 8.39109 13.1942 8.22355 13.3054L4.03427 16.0851C3.64843 16.3411 3.1519 15.9804 3.27616 15.5343L4.62529 10.6911C4.67924 10.4974 4.61193 10.2902 4.45444 10.1652L0.516183 7.03997C0.153466 6.75213 0.343122 6.16843 0.805754 6.14876L5.82885 5.93522C6.02973 5.92668 6.20596 5.79864 6.27615 5.61024L8.03146 0.898974Z"
              fill="#F3BE00"
            />
          </svg>
        ) : (
          <svg
            id="star"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="star"
              d="M8.5 1.43212L10.2553 6.14338C10.3957 6.5202 10.7482 6.77627 11.1499 6.79335L16.173 7.00689L12.2348 10.1321C11.9198 10.3821 11.7851 10.7964 11.893 11.1838L13.2422 16.0271L9.05289 13.2473C8.71782 13.025 8.28218 13.025 7.94711 13.2473L3.75782 16.0271L5.10695 11.1838C5.21486 10.7964 5.08024 10.3821 4.76525 10.1321L0.826991 7.00689L5.85009 6.79335C6.25185 6.77627 6.60429 6.5202 6.74469 6.14338L8.5 1.43212Z"
              stroke="#93ABC0"
            />
          </svg>
        )}
      </Star>

      <InnerContainer>
        <Folder
          src={isFolder ? "/folderIcon.svg" : "/processIcon.svg"}
          alt="folder icon"
        />
        <Avatar src={photo || "/noAvatar.svg"} alt="avatar" />
      </InnerContainer>

      <span>{name}</span>
    </Container>
  );
};

export default CatalogItem;
