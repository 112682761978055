import React from "react";

import {
  Container,
  EditButton,
  DeleteButton,
  Name,
  Number,
  Type,
  Label,
  Required,
  Predefined,
} from "./styles";
import { icons } from "./constants";

export const AttributeCard = ({
  attribute,
  index,
  onEdit,
  onDelete,
  onClick,
  isSelected,
}) => {
  const selectIcon = (type) => {
    if (type === "Булево") return icons.boolean;
    if (type === "Строка") return icons.string;
    if (type === "Число") return icons.number;
    return icons.directories;
  };

  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <div>
        <Number>{index}.</Number>
        <Name>{attribute.name}</Name>
      </div>

      <div>
        <Type>
          <span>Тип значения</span>
          <span
            title={
              attribute.type.app_type.includes("Справочник")
                ? "Справочник"
                : attribute.type.app_type
            }
          >
            {selectIcon(attribute.type.app_type)}
          </span>
        </Type>

        <Required>
          <Label>
            Обязательный
            <input
              type="checkbox"
              defaultChecked={attribute.required}
              disabled
            />
          </Label>
        </Required>

        {attribute.predefined ? (
          <Predefined>Определён системой</Predefined>
        ) : (
          <>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <img src="/editIcon.svg" alt="edit" />
              Редактировать
            </EditButton>
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <img src="/trashBinIcon.svg" alt="" />
            </DeleteButton>
          </>
        )}
      </div>
    </Container>
  );
};
