import styled from "styled-components";
import arrowTop from "../../assets/arrowTop.svg";
import arrowBottom from "../../assets/arrowBottom.svg";

export const Container = styled.div`
  // padding: 30px 40px;
  height: calc(100vh - 222px);

  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  grid-template-rows: repeat(auto-fill, 246px);
  grid-gap: 20px;

  background-color: #fafcfe;
`;

export const Breadcrumbs = styled.div`
  width: 100%;
  height: 42px;
  background: #eff3f9;
  border-top: 1px solid #cbd0dc;
  border-bottom: 1px solid #cbd0dc;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
`;

export const AddEmployeeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  width: 208px;
  height: 40px;
  border: 1px solid #d95a45;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;

  img {
    margin-right: 16px;
  }
`;

export const MainInner = styled.div`
  height: ${({ isItemSelected }) =>
    isItemSelected ? "calc(100vh - 411px)" : "calc(100vh - 202px)"};
  padding: 0 40px;
  background-color: #fafcfe;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;
