import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState(null);

  const storedToken = window.localStorage.getItem("token");

  if (!isAuth && storedToken) {
    setIsAuth(true);
    setToken(storedToken);
  }

  const signIn = (jwtToken, cb) => {
    setIsAuth(true);
    setToken(jwtToken);
    window.localStorage.setItem("token", jwtToken);
    cb();
  };

  const signOut = () => {
    setIsAuth(false);
    setToken("");
    window.localStorage.removeItem("token");
    navigate("/login", { replace: true });
  };

  const value = {
    isAuth,
    setIsAuth,
    token,
    signIn,
    userName,
    setUserName,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
