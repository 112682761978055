import React from "react";
import { StyledButton } from "./styles";

export const DirectoriesAddButton = ({ onClick, children, ...rest }) => {
  return (
    <StyledButton outlined onClick={onClick} {...rest}>
      {/* <img src="/smallPlusIcon.svg" alt="plus" /> */}
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.55826 4.768H5.15026V8.224H3.79026V4.768H0.398262V3.536H3.79026V0.0639992H5.15026V3.536H8.55826V4.768Z"
          fill="#D95A45"
        />
      </svg>
      Добавить {children}
    </StyledButton>
  );
};
