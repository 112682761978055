import styled from "styled-components";
import Select from "react-select";

import { Button } from "../../Buttons";
import { Modal } from "../Modal";

export const Container = styled.div`
  position: fixed;
  top: 30vh;
  left: 34vw;
  width: 613px;
  z-index: 100;
  background: #f8fafc;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h2`
  align-self: center;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
`;

export const Form = styled.form`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.img`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  align-self: flex-end;
  margin: 12px 12px 0 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #7a8fa3;

  span {
    margin-bottom: 6px;
  }
`;

export const SelectElement = styled(Select)`
  .react-select-container {
  }

  .react-select__menu {
    z-index: 2;
  }

  .react-select__control {
    padding-left: 12px;
    box-shadow: none;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }

  .react-select__option {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;

    &:hover {
      background-color: #eeeeee;
      border: 1px solid #e8ecef;
    }

    &--is-selected {
      color: #203b54;
      background-color: #eeeeee;

      &:hover {
        color: black;
      }
    }

    &--is-focused {
      background-color: transparent;
    }
  }
`;

export const Textarea = styled.textarea`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 80px;
  font-family: Noto sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  outline: none;
  padding: 9px 22px;
  resize: none;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  letter-spacing: 0.0041em;
`;

export const StyledModal = styled(Modal)`
  overflow-y: visible;
`;
