import styled from "styled-components";
import Select from "react-select";

import { Button } from "../../Buttons";

export const Container = styled.div`
  position: fixed;
  top: 30vh;
  left: 34vw;
  width: 613px;
  z-index: 100;
  overflow: hidden;
  background: #f8fafc;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Noto-Sans, sans-serif;
`;

export const Header = styled.h2`
  align-self: center;
`;

export const Form = styled.form`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.img`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  align-self: flex-end;
  margin: 12px 12px 0 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #7a8fa3;
`;

export const Input = styled.input`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  padding-left: 22px;
  outline: none;
`;

export const StyledSelect = styled(Select)`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 40px;
`;

export const Option = styled.option`
  width: 554px;
  height: 40px;
  padding: 10px 0 10px 22px;
  background: #f8fafc;
  border: 1px solid #cbd0dc;
  box-sizing: border-box;
  appearance: none;
  :hover {
    background: #f8fafc;
    border: 1px solid #cbd0dc;
  }
`;

export const Textarea = styled.textarea`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 80px;
  padding: 5px 22px;
  font-family: Noto-Sans, sans-serif;
  font-size: 14px;
  outline: none;
  resize: none;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  letter-spacing: 0.0041em;
`;
