import styled from "styled-components";

export const NumberInput = styled.input`
  margin-left: 22px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  margin-bottom: 8px;
`;

export const TextArea = styled.textarea`
  background-color: transparent;
  border: none;
  width: 100%;
  resize: none;
  padding: 0 22px;

  overflow-y: hidden;

  font-family: Noto sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;

  &:focus {
    outline: none;
  }
`;
