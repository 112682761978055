import styled from "styled-components";

export const Container = styled.li`
  min-height: 44px;
  // padding: 10px 24px;
  border-bottom: ${({ status }) => {
    if (status === "new") return "1px solid #E5BFAA";
    if (status === "inprogress") return "1px solid #E6DEA7";
    return "1px solid #ADDCA4";
  }};

  &:hover {
    background-color: ${({ status }) => {
      if (status === "new") return "#FADDCD";
      if (status === "inprogress") return "#FBF5D0";
      return "#CCF9C4";
    }};
  }

  transition: background-color 0.2s;
`;

export const AttributeName = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 24px 5px;
  font-weight: 300;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #7a8fa3;

  //span:first-child {
  //  height: 16px;
  //  margin-right: 10px;
  //}
  //
  //span:nth-child(2) {
  //  overflow: hidden;
  //  white-space: nowrap;
  //  text-overflow: ellipsis;
  //}
`;

export const AttributeValue = styled.div`
  //margin: 10px 24px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;

  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;

    img {
      width: 19px;
      height: 19px;
    }
  }
`;

export const AddValueButton = styled.button`
  padding: 12px 0 10px 22px;
  width: 100%;
  align-self: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  text-decoration-line: underline;
  color: #203b54;
  background-color: ${({ status }) => {
    if (status === "new") return "#F7D9C8";
    if (status === "inprogress") return "#F8F0C2";
    return "#D2F2CC";
  }};
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #d95a45;
    circle {
      stroke: #d95a45;
    }
    path {
      fill: #d95a45;
    }
  }

  > svg {
    margin: 0 11px 0 0;
  }
`;
