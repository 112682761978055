import React, { useState } from "react";

import { Loader } from "../../Loaders";

import {
  CloseButton,
  Form,
  Input,
  Label,
  SubmitButton,
  Header,
  Textarea,
} from "./styles";
import { Modal } from "../Modal";

export const ProgramModal = ({
  isEdit,
  program,
  isLoading,
  onCreate,
  onEdit,
  onClose,
}) => {
  const [name, setName] = useState(program?.name || "");
  const [comment, setComment] = useState(program?.comment || "");

  const buttonText = isEdit ? "Сохранить" : "Создать";

  const handleSubmit = (e) => {
    e.preventDefault();
    return isEdit
      ? onEdit({
          name,
          comment,
        })
      : onCreate({
          name,
          comment,
        });
  };

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form onSubmit={handleSubmit}>
        <Header>{isEdit ? "Редактировать" : "Добавить"} программу</Header>
        <Label>
          Название
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <Label>
          Комментарий
          <Textarea
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : buttonText}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
