import React from "react";

import { Container, EditButton, DeleteButton, Name, Number } from "./styles";

export const ProgramCard = ({
  program,
  index,
  onEdit,
  onDelete,
  onClick,
  isSelected,
}) => {
  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <div>
        <Number>{index}.</Number>
        <Name>{program.name}</Name>
      </div>
      <div>
        <EditButton onClick={onEdit}>
          <img src="/editIcon.svg" alt="edit" />
          Редактировать
        </EditButton>
        <DeleteButton onClick={onDelete}>
          <img src="/trashBinIcon.svg" alt="" />
        </DeleteButton>
      </div>
    </Container>
  );
};
