import React from "react";
import { Outlet } from "react-router-dom";

import { Navbar } from "../Navbar";
import { Container } from "./styles";

export const Layout = () => {
  return (
    <Container>
      <Navbar />
      <Outlet />
    </Container>
  );
};
