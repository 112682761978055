import React from "react";

import {
  Container,
  Delete,
  Name,
  Processes,
  ProfilePic,
  EditButton,
} from "./styles";

export const EmployeeCard = ({
  employee,
  onDelete,
  onEdit,
  onClick,
  selected,
}) => {
  return (
    <Container onClick={onClick} selected={selected}>
      <Delete onClick={onDelete} src="/trashBinIcon.svg" alt="tray" />
      <ProfilePic src={employee.photo} alt="avatar" />
      <Name>{employee.name}</Name>
      <Processes>
        Процессы<span>{employee.processes}</span>
      </Processes>
      <EditButton onClick={onEdit}>
        <img src="/editIcon.svg" alt="edit" />
        Редактировать
      </EditButton>
    </Container>
  );
};
