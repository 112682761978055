import React from "react";
import { Backdrop, Container } from "./styles";

export const Modal = ({ className, maxWidth, children }) => {
  return (
    <>
      <Backdrop />
      <Container className={className} maxWidth={maxWidth}>
        {children}
      </Container>
    </>
  );
};
