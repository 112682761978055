import React, { useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { baseUrl, getSubdomainName } from "../../../../api";
import { useAuth } from "../../../../hooks/useAuth";
import { Container, SelectElement } from "./styles";
import { EmployeeModal } from "../../../UI/Modals/EmployeeModal";
import { RoleModal } from "../../../UI/Modals/RoleModal";
import { ProgramModal } from "../../../UI/Modals/ProgramModal";
import { DocumentModal } from "../../../UI/Modals/DocumentModal";
import { FormModal } from "../../../UI/Modals/FormModal";
import { AttributeModal } from "../../../UI/Modals/AddAttributeModal";

export const ReferenceAttribute = ({
  attribute,
  attrId,
  attrType,
  value,
  stageId,
}) => {
  const { token } = useAuth();
  const params = useParams();
  const queryClient = useQueryClient();

  const [isCreateModalShown, setIsCreateModalShown] = useState(false);

  const selectRef = useRef(null);

  const directoryName = attrType
    ? attrType.split(".")[1]
    : attribute.type.app_type.split(".")[1];

  const getDirectoryUrl = (name, arg = false) => {
    if (name === "Сотрудники") return "employees";
    if (name === "Роли") return "roles";
    if (name === "Программы") return "programs";
    if (name === "Документы") return "docs";
    if (name === "Формы") return "forms";
    if (name === "Атрибуты") return "attributes";
    return arg ? "free_ref" : `freeref/${attrId || attribute.id}`;
  };

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery(["options", directoryName], async () =>
    axios.get(`${baseUrl}/catalog/${getDirectoryUrl(directoryName)}`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const editMutation = useMutation(
    "editLine",
    async (newValue) => {
      await axios.put(
        `${baseUrl}/catalog/stage_attribute_edit/${stageId}`,
        {
          attribute_id: attrId || attribute.id,
          multiline_stub:
            value?.multiline_stub || attribute?.value?.multiline_stub,
          value: newValue,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["editProcess", params.id]);
      },
    }
  );

  const createMutation = useMutation(
    "createAttribute",
    async (data) => {
      await axios.post(
        `${baseUrl}/catalog/${getDirectoryUrl(directoryName)}`,
        data,
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["options", directoryName]);
        setIsCreateModalShown(false);
      },
    }
  );

  const options = [];

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>{error.message}</div>;

  if (response && !attrId && !attribute.multiple_values) {
    response?.data?.data[getDirectoryUrl(directoryName, true)].map((item) => {
      options.push({
        value: item.id,
        label: item.name,
      });
      return null;
    });
  }

  if (attrType) {
    response?.data?.data[getDirectoryUrl(directoryName, true)].map((item) => {
      options.push({
        value: item.id,
        label: item.name,
      });
      return null;
    });
  }

  options.push({ value: "create", label: "+ Создать" });

  const handleChange = (selectedOption) => {
    if (selectedOption.value === "create") {
      setIsCreateModalShown(true);
      selectRef.current.clearValue();
      return;
    }

    editMutation.mutate(selectedOption.value);
  };

  const customFilter = (option, inputValue) => {
    if (inputValue) {
      return option.label.includes(inputValue) || option.label === "+ Создать";
    }
    return option;
  };

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const getModal = (name) => {
    if (name === "Сотрудники")
      return (
        <EmployeeModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    if (name === "Роли")
      return (
        <RoleModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    if (name === "Программы")
      return (
        <ProgramModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    if (name === "Документы")
      return (
        <DocumentModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    if (name === "Формы")
      return (
        <FormModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    if (name === "Атрибуты")
      return (
        <AttributeModal
          isLoading={createMutation.isLoading}
          onCreate={handleCreate}
          onClose={() => setIsCreateModalShown(false)}
        />
      );
    return <div>FREEREF</div>;
  };

  return (
    <Container>
      <SelectElement
        ref={selectRef}
        onChange={handleChange}
        classNamePrefix="react-select"
        options={options}
        filterOption={customFilter}
        placeholder="Выбрать"
        defaultValue={
          value
            ? options.find((option) => option.label === value.value.name)
            : options.find(
                (option) => option.label === attribute.value.value.name
              )
        }
      />
      {isCreateModalShown && getModal(directoryName)}
    </Container>
  );
};
