import React, { useState } from "react";

import {
  Form,
  CloseButton,
  Label,
  Header,
  Textarea,
  SubmitButton,
} from "./styles";
import { Loader } from "../../Loaders";
import { Modal } from "../Modal";

export const EditProcessCommentModal = ({
  isLoading,
  commentToEdit,
  onEdit,
  onClose,
}) => {
  const [comment, setComment] = useState(commentToEdit);

  const handleSubmit = (e) => {
    e.preventDefault();
    onEdit(comment);
  };

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />

      <Form onSubmit={(e) => handleSubmit(e)}>
        <Header>Редактировать комментарий</Header>

        <Label>
          Комментарий
          <Textarea
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : "Сохранить"}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
