import React, { useState } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Container,
  InfoProcessContainer,
  CellName,
  Cell,
  ProgressBar,
  Progress,
  ProgressText,
  ProgressTextNumber,
  FlexContainer,
  FlexContainerForComment,
  EditButton,
  UpdateBusinessProcess,
  DashboardContainer,
  Red,
  Yellow,
  Green,
  Avatar,
  StyledContextMenu,
  StyledMenuItem,
  EmptyDashboardContainer,
  AddStageButtonBig,
  ExcelDownload,
  DashboardLoader,
} from "./styles";

import { CardComponent } from "../../components/Card/Card";
import { baseUrl, getSubdomainName } from "../../api";
import { useAuth } from "../../hooks/useAuth";
import { AddStageModal } from "../../components/UI/Modals/AddStageModal";
import { Breadcrumbs } from "../CatalogPage/styles";
import { EditProcessCommentModal } from "../../components/UI/Modals/EditProcessCommentModal";
import { ConfirmDeleteModal } from "../../components/UI/Modals/ConfirmDeleteModal";
import { AddRelationModal } from "../../components/UI/Modals/AddRelationModal";

export const DashboardPage = () => {
  const { token } = useAuth();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isAddStageModalShown, setIsAddStageModalShown] = useState(false);
  const [isEditCommentModalShown, setIsEditCommentModalShown] = useState(false);
  const [isAddRelationModalShown, setIsAddRelationModalShown] = useState(false);
  const [stageToCreateRelationId, setStageToCreateRelationId] = useState(null);

  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [stageToDeleteId, setStageToDeleteId] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(null);

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: response,
  } = useQuery("getProcess", async () =>
    axios.get(`${baseUrl}/catalog/processes/view/${id}`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const editMutation = useMutation(
    "editItem",
    async (comment) => {
      await axios.put(
        `${baseUrl}/catalog/processes/${response?.data?.data?.process.id}`,
        {
          name: response?.data?.data?.process.name,
          parent_id: response?.data?.data?.process.parent_id,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsEditCommentModalShown(false);
        queryClient.invalidateQueries("getProcess");
      },
    }
  );

  const createMutation = useMutation(
    "addStage",
    async ({ name, number }) => {
      await axios.post(
        `${baseUrl}/catalog/processes/${id}/stages`,
        {
          name,
          number,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProcess");
        setIsAddStageModalShown(false);
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteStage",
    async (stageId) => {
      await axios.delete(
        `${baseUrl}/catalog/processes/${id}/stages/${stageId}`,
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsDeleteModalShown(false);
        setStageToDeleteId(null);
        queryClient.invalidateQueries("getProcess");
      },
    }
  );

  const createRelationMutation = useMutation(
    "addRelation",
    async ({ stageId, relationId }) => {
      await axios.post(
        `${baseUrl}/catalog/stage_relations`,
        {
          stage_id: stageId,
          relation_id: relationId,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProcess");
        setIsAddRelationModalShown(false);
      },
    }
  );

  const deleteRelationMutation = useMutation(
    "deleteRelation",
    async ({ stageId, relationId }) => {
      await axios.delete(`${baseUrl}/catalog/stage_relations`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
        data: {
          stage_id: stageId,
          relation_id: relationId,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getProcess");
      },
    }
  );

  const handleDownloadExcel = () => {
    try {
      axios
        .get(`${baseUrl}/catalog/processes/${id}/excel`, {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((resp) => {
          const filename = resp.headers["content-disposition"]
            .split("filename=")[1]
            .split('"')[1];
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", decodeURI(filename));
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateRelation = (data) => {
    createRelationMutation.mutate(data);
  };

  const handleDeleteRelation = (stageId, relationId) => {
    deleteRelationMutation.mutate({ stageId, relationId });
  };

  const handleCreateStage = (data) => {
    createMutation.mutate(data);
    setIsAddStageModalShown(false);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (data) => {
    deleteMutation.mutate(data);
  };

  const formatNumber = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const formatTime = (hours) => {
    if (hours / 24 < 1) return `${hours} ч`;
    const days = Math.floor(hours / 24);
    const remainder = hours % 24;
    const remHours = Math.floor(remainder);
    return `${days} д ${remHours} ч`;
  };

  const handleClick = (e, item, index) => {
    e.stopPropagation();
    switch (e.detail) {
      case 1:
        e.stopPropagation();
        setSelectedStageId(item.id);
        break;
      case 2:
        navigate(`/edit/${id}`, { state: index });
        break;
      default:
        break;
    }
  };

  if (isLoading || isFetching) return <DashboardLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <>
      <Breadcrumbs>
        {[
          {
            id: 0,
            name: "Бизнес-процессы",
          },

          ...response.data.data.process.bread_crumbs,
          { name: response.data.data.process.name },
        ].map((crumb, index, array) => {
          if (index !== array.length - 1) {
            return (
              <Link to={`/catalog/${crumb.id}`} key={`${crumb.id}${index}`}>
                <span>{crumb.name}</span>
                <img
                  style={{ margin: "0 10px" }}
                  src="/breadCrumbArrow.svg"
                  alt="arrow"
                />
              </Link>
            );
          }
          return <span key={`${crumb.id}${index}`}>{crumb.name}</span>;
        })}
      </Breadcrumbs>

      <Container>
        <InfoProcessContainer>
          <UpdateBusinessProcess>
            <Link to={`/edit/${id}`}>Редактировать бизнес-процесс</Link>
          </UpdateBusinessProcess>

          <ExcelDownload onClick={handleDownloadExcel}>
            <img src="/excelIcon.svg" alt="excel" />
            <span>Выгрузить в Excel</span>
          </ExcelDownload>

          <CellName>Наименование процесса</CellName>
          <Cell>{response?.data?.data?.process?.name}</Cell>
          <CellName>Автор</CellName>
          <FlexContainer>
            <Cell>{response?.data?.data?.process?.author?.name}</Cell>
            <Avatar
              src={
                response?.data?.data?.process?.author?.photo || "/noAvatar.svg"
              }
              alt="avatar"
            />
          </FlexContainer>
          <CellName>Миссия</CellName>
          <Cell>{response?.data?.data?.process?.mission}</Cell>
          <CellName>Создаваемая ценность</CellName>
          <Cell>{response?.data?.data?.process?.value}</Cell>
          <CellName>Результат</CellName>
          <Cell>{response?.data?.data?.process?.result}</Cell>
          <CellName>Основание старта</CellName>
          <Cell>{response?.data?.data?.process?.launch_base}</Cell>
          <CellName>Максимальное время выполнения</CellName>
          <Cell>{formatTime(response?.data?.data?.process.t_max)}</Cell>
          <CellName>Нормативное время выполнения</CellName>
          <Cell>{formatTime(response?.data?.data?.process.t_norm)}</Cell>
          <CellName>Стоимость</CellName>
          <Cell>{formatNumber(response?.data?.data?.process.cost)} руб.</Cell>
          <CellName>Прогресс работы</CellName>

          <Progress>
            <ProgressText>Новых</ProgressText>
            <Red />
            <ProgressTextNumber>
              {response?.data?.data?.process.stages_new} /{" "}
              {response?.data?.data?.process.stages_total}
            </ProgressTextNumber>
          </Progress>
          <Progress>
            <ProgressText>В процессе</ProgressText>
            <Yellow />
            <ProgressTextNumber>
              {response?.data?.data?.process.stages_inprogress} /{" "}
              {response?.data?.data?.process.stages_total}
            </ProgressTextNumber>
          </Progress>
          <Progress>
            <ProgressText>Готовых</ProgressText>
            <Green />
            <ProgressTextNumber>
              {response?.data?.data?.process.stages_ready} /{" "}
              {response?.data?.data?.process.stages_total}
            </ProgressTextNumber>
          </Progress>

          <CellName>Общий прогресс</CellName>

          <ProgressBar>
            <progress
              value={response?.data?.data?.process.stages_ready}
              max={response?.data?.data?.process.stages_total}
            />
          </ProgressBar>

          <FlexContainerForComment>
            <CellName>Комментарий</CellName>
            <EditButton onClick={() => setIsEditCommentModalShown(true)}>
              <img src="/editIcon.svg" alt="edit" />
            </EditButton>
          </FlexContainerForComment>

          <Cell>{response?.data?.data?.process.comment}</Cell>
        </InfoProcessContainer>

        {!response?.data?.data?.process?.stages.length ? (
          <EmptyDashboardContainer>
            <AddStageButtonBig onClick={() => setIsAddStageModalShown(true)}>
              <span>
                <img src="/bigPlusIcon.svg" alt="add" />
              </span>
              <span>Добавить этап</span>
            </AddStageButtonBig>
          </EmptyDashboardContainer>
        ) : (
          <DashboardContainer>
            {response?.data?.data?.process.stages.map((item, index) => (
              <CardComponent
                onClick={(e) => handleClick(e, item, index + 1)}
                handleAddRelation={() => {
                  setStageToCreateRelationId(item.id);
                  setIsAddRelationModalShown(true);
                }}
                handleDeleteRelation={handleDeleteRelation}
                onContextMenu={() => setSelectedStageId(item.id)}
                selectedId={selectedStageId}
                key={item.id}
                item={item}
              />
            ))}
          </DashboardContainer>
        )}

        {isAddStageModalShown && (
          <AddStageModal
            onCreate={handleCreateStage}
            isLoading={createMutation.isLoading}
            onClose={() => setIsAddStageModalShown(false)}
          />
        )}

        <StyledContextMenu id="processMenu">
          <StyledMenuItem>
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={`/edit/${id}`}
            >
              Редактировать
            </Link>
          </StyledMenuItem>
        </StyledContextMenu>

        {isAddStageModalShown && (
          <AddStageModal
            onCreate={handleCreateStage}
            onClose={() => setIsAddStageModalShown(false)}
          />
        )}

        {isEditCommentModalShown && (
          <EditProcessCommentModal
            commentToEdit={response?.data?.data?.process.comment}
            isLoading={editMutation.isLoading}
            onEdit={handleEdit}
            onClose={() => setIsEditCommentModalShown(false)}
          />
        )}

        {isDeleteModalShown && (
          <ConfirmDeleteModal
            heading="этап"
            onDelete={() => handleDelete(stageToDeleteId)}
            isLoading={deleteMutation.isLoading}
            onClose={() => setIsDeleteModalShown(false)}
          />
        )}

        {isAddRelationModalShown && (
          <AddRelationModal
            stageId={stageToCreateRelationId}
            createRelation={handleCreateRelation}
            processId={id}
            isLoading={createRelationMutation.isLoading}
            onClose={() => setIsAddRelationModalShown(false)}
          />
        )}
      </Container>
    </>
  );
};
