import styled from "styled-components";
import { Item, Menu } from "react-contexify";
import arrowTop from "../../assets/arrowTop.svg";
import arrowBottom from "../../assets/arrowBottom.svg";
import { MainLoader } from "../../components/UI/Loaders/MainLoader";

export const Container = styled.div`
  height: calc(100vh - 116px);
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const InfoProcessContainer = styled.div`
  width: 381px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
  margin-left: -1px;
  background: #ffffff;
  border: 1px solid #cbd0dc;
  box-sizing: border-box;
  padding: 0 20px 40px;
`;

export const CellName = styled.div`
  margin-top: 20px;
  margin-right: 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #7a8fa3;
`;

export const Cell = styled.div`
  max-width: 100%;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #203b54;
`;

export const ProgressBar = styled.div`
  progress {
    width: 97%;
    height: 10px;
    border-radius: 10px;
    border: 0 solid #dde9f2;
    &::-webkit-progress-value {
      background: linear-gradient(89.91deg, #522d75 1.79%, #c84046 95.83%);
      border-radius: 10px;
    }
    &::-webkit-progress-bar {
      background: #dde9f2;
      border-radius: 10px;
    }
  }
`;

export const Progress = styled.div`
  margin-top: 4px;
`;

export const ProgressText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #203b54;
`;

export const ProgressTextNumber = styled(ProgressText)`
  margin-left: 10px;
  font-weight: 700;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexContainerForComment = styled(FlexContainer)``;

export const EditButton = styled.button`
  margin-top: 14px;
  background: #ffffff;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UpdateBusinessProcess = styled.div`
  margin-top: 20px;
  a {
    color: #d95a45;
    font-size: 14px;
  }
`;

export const DashboardContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 284px);
  grid-template-rows: repeat(auto-fill, 304px);
  grid-gap: 40px;
  background: #fafcfe;
  padding: 10px 0 0 35px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    transform: rotate(90deg);
  }
  &::-webkit-scrollbar-track {
    background: #eff3f6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c5d4df;
  }
  ::-webkit-scrollbar-corner {
    background: #eff3f6;
  }
  &::-webkit-scrollbar-button {
    display: block;
    background-size: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url(${arrowTop});
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url(${arrowBottom});
    background-repeat: no-repeat;
  }
`;

export const EmptyDashboardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fafcfe;
`;

export const AddStageButton = styled.button`
  margin: 30px 0 0;
  padding: 12px 30px;
  border: 1px solid #d95a45;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  cursor: pointer;
`;

export const AddStageButtonBig = styled.div`
  width: 193px;
  height: 189px;
  border: 1px solid #d95a45;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  span:first-child {
    width: 40px;
    height: 40px;
    margin: 55px 75px 42px;
  }

  span:last-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    color: #203b54;
  }
`;

export const Red = styled.div`
  display: inline-block;
  margin-left: 5px;
  width: 13px;
  height: 13px;
  background: #fbe7dc;
  border: 1px solid #e5bfaa;
  border-radius: 50px;
`;

export const Yellow = styled.div`
  display: inline-block;
  margin-left: 5px;
  width: 13px;
  height: 13px;
  background: #fbf7dc;
  border: 1px solid #d1ca99;
  border-radius: 50px;
`;

export const Green = styled.div`
  display: inline-block;
  margin-left: 5px;
  width: 13px;
  height: 13px;
  background: #e1fbdc;
  border: 1px solid #7ec571;
  border-radius: 50px;
`;

export const Avatar = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 18px;
`;

export const StyledContextMenu = styled(Menu)`
  position: fixed;
  padding: 5px 0;
  background: #fefefe;
  border: 1px solid #e7eaf0;
  box-sizing: border-box;
  box-shadow: -2px 4px 8px rgba(113, 128, 158, 0.25);
`;

export const StyledMenuItem = styled(Item)`
  padding: 10px 22px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #203b54;

  &:hover {
    background: #e7eaf0;
  }
`;

export const Breadcrumbs = styled.div`
  width: 100%;
  height: 42px;
  background: #eff3f9;
  border-top: 1px solid #cbd0dc;
  border-bottom: 1px solid #cbd0dc;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 13px;

  a {
    text-decoration: none;
    color: #203b54;
  }
`;

export const ExcelDownload = styled.div`
  margin-top: 25px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  span {
    font-family: Noto sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.0041em;
    text-decoration-line: underline;
    color: #203b54;
  }
`;

export const DashboardLoader = styled(MainLoader)`
  position: absolute;
  top: 50vh;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;
