import styled from "styled-components";
import { Button } from "../../Buttons";

export const Container = styled.div`
  position: fixed;
  top: 30vh;
  left: 34vw;
  width: 613px;
  z-index: 100;
  overflow: hidden;
  background: #f8fafc;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h2`
  align-self: center;
`;

export const Form = styled.form`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.img`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  align-self: flex-end;
  margin: 12px 12px 0 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #7a8fa3;
`;

export const Input = styled.input`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  outline: none;
  padding-left: 22px;

  font-family: Noto sans, sans-serif;
`;

export const Textarea = styled.textarea`
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #e7edef;
  box-shadow: 0 4px 54px rgba(165, 170, 181, 0.1);
  border-radius: 10px;
  height: 80px;
  font-size: 14px;
  outline: none;
  padding: 5px 22px;
  resize: none;

  font-family: Noto sans, sans-serif;
`;

export const SubmitButton = styled(Button)`
  align-self: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  letter-spacing: 0.0041em;
`;
