import styled from "styled-components";

export const TooltipText = styled.div`
  color: #7a8fa3;
  padding-right: 6px;
  max-width: 215px;

  display: flex;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    svg {
      fill: white;
    }
  }
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: 12px;
  left: 26px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  padding: 2px 4px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  z-index: 100;

  width: max-content;

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #203b54;
    background-color: #fafcfe;
    padding: 2px 4px;
    //width: 230px;
    //&:before {
    //  border-color: transparent transparent rgba(0, 0, 0, 0.8)
    //    rgba(0, 0, 0, 0.8);
    //}
  }
`;
