import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { MainLoader } from "../../components/UI/Loaders/MainLoader";

export const Container = styled.div``;

export const TopBar = styled.div`
  height: 48px;
  display: flex;
  padding-left: 45px;
  border-bottom: 1px solid #cbd0dc;
`;

export const TopBarButton = styled(NavLink)`
  background-color: #fafcfe;
  display: flex;
  align-items: end;
  cursor: pointer;
  margin-right: 28px;
  color: #4d6276;
  text-decoration: none;
  padding: 0 4px 6px;
  border: none;

  &[class*="active"] {
    border-bottom: 2px solid #d95a45;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 5px;
  background-color: #fff;
  border-bottom: 1px solid #cbd0dc;
`;

export const DirectoriesLoader = styled(MainLoader)`
  position: absolute;
  top: 50vh;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);
`;
