import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import {
  AddButton,
  AttributeList,
  Container,
  DeleteButton,
  Footer,
  Header,
  Number,
  Status,
  CirclesContainer,
  ProcessCircle,
  Stop,
  StatusContainer,
  AddConnectionButton,
} from "./styles";
import { Attribute } from "./Attribute";
import { DeleteMenuItem, MenuProcessCircle } from "../Card/styles";

export const StageCard = ({
  handleAddAttribute,
  item,
  onEditStage,
  onDelete,
  onRelationDelete,
  handleAddRelation,
}) => {
  function displayStatus(stat) {
    if (stat === "new") return "Новый";
    if (stat === "ready") return "Готов";
    return "В процессе";
  }

  return (
    <Container status={item.status}>
      {item.can_stop && (
        <Stop>
          <span>stop</span>
        </Stop>
      )}
      <Number>{item.number}</Number>
      <StatusContainer>
        <Status status={item.status}>{displayStatus(item.status)}</Status>
        <img src="/editIcon.svg" alt="edit" onClick={onEditStage} />
      </StatusContainer>
      <Header>{item.name}</Header>
      <AddButton status={item.status} onClick={handleAddAttribute}>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9.5" cy="9.5" r="9" fill="#203B54" stroke="#203B54" />
          <path
            d="M13.151 9.80921H10.169V12.8332H8.97897V9.80921H6.01097V8.73121H8.97897V5.69321H10.169V8.73121H13.151V9.80921Z"
            fill="white"
          />
        </svg>
        Добавить атрибут
      </AddButton>
      <AttributeList>
        {item.attributes.map((attribute) => (
          <Attribute
            key={attribute.id}
            attribute={attribute}
            status={item.status}
            stageId={item.id}
          />
        ))}
      </AttributeList>

      <Footer>
        {item.bind_in.length > 0 && (
          <CirclesContainer>
            <img src="/arrowRightIcon.svg" alt="" />
            {item.bind_in.map((i) => (
              <ProcessCircle key={i.number}>{i.number}</ProcessCircle>
            ))}
          </CirclesContainer>
        )}
        {item.bind_out.length > 0 && (
          <CirclesContainer>
            <img src="/arrowLeftIcon.svg" alt="" />
            {item.bind_out.map((i) => (
              <Menu
                direction="right"
                key={i.id}
                menuButton={({ open }) => (
                  <MenuProcessCircle open={open}>{i.number}</MenuProcessCircle>
                )}
                transition
              >
                <MenuItem>
                  {i.number}. {i.name}
                </MenuItem>
                <DeleteMenuItem onClick={() => onRelationDelete(item.id, i.id)}>
                  <img src="/deleteAttributeValueIcon.svg" alt="delete" />
                  Удалить связь
                </DeleteMenuItem>
              </Menu>
            ))}
          </CirclesContainer>
        )}
        <AddConnectionButton onClick={handleAddRelation}>
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.5" cy="9.5" r="9" stroke="#203B54" />
            <path
              d="M13.151 9.80921H10.169V12.8332H8.97897V9.80921H6.01097V8.73121H8.97897V5.69321H10.169V8.73121H13.151V9.80921Z"
              fill="#203B54"
            />
          </svg>
        </AddConnectionButton>
      </Footer>

      <DeleteButton status={item.status} onClick={() => onDelete(item.id)}>
        <img src="/trashBinIcon.svg" alt="delete" />
        Удалить этап
      </DeleteButton>
    </Container>
  );
};
