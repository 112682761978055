import React, { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Container, MainInner } from "./styles";
import { EmployeeCard } from "./EmployeeCard";
import { baseUrl, getSubdomainName } from "../../api";
import { useAuth } from "../../hooks/useAuth";
import { ConfirmDeleteModal } from "../UI/Modals/ConfirmDeleteModal";
import { EmployeeModal } from "../UI/Modals/EmployeeModal";
import { Breadcrumbs } from "../Programs/styles";
import { DirectoriesAddButton } from "../UI/DirectoriesAddButton";
import { DirectoriesLoader } from "../../pages/DirectoryPage/styles";

export const Employees = () => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  const [employeeToDeleteId, setEmployeeToDeleteId] = useState(null);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isEmployeeModalShown, setIsEmployeeModalShown] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery("getAllEmployees", async () =>
    axios.get(`${baseUrl}/catalog/employees`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const createMutation = useMutation(
    "addEmployee",
    async ({ name, photo, fileName, communication, comment }) => {
      await axios.post(
        `${baseUrl}/catalog/employees`,
        {
          name,
          photo,
          file_name: fileName,
          communication,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllEmployees");
        setIsEmployeeModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editEmployee",
    async ({ name, photo, fileName, communication, comment }) => {
      await axios.put(
        `${baseUrl}/catalog/employees/${employeeToEdit.id}`,
        {
          name,
          photo,
          file_name: fileName,
          communication,
          comment,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsEmployeeModalShown(false);
        queryClient.invalidateQueries("getAllEmployees");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteEmployee",
    async (id) => {
      await axios.delete(`${baseUrl}/catalog/employees/${id}`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllEmployees");
        setIsDeleteModalShown(false);
      },
    }
  );

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  if (isLoading) return <DirectoriesLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <>
      <Breadcrumbs>
        <div>
          <span>Справочники</span>
          <img
            style={{ margin: "0 10px" }}
            src="/breadCrumbArrow.svg"
            alt="arrow"
          />
        </div>
        <div>
          <span>Сотрудники</span>
        </div>
      </Breadcrumbs>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "#fafcfe",
        }}
      >
        <DirectoriesAddButton
          onClick={() => {
            setEmployeeToEdit(null);
            setIsEmployeeModalShown(true);
          }}
        >
          сотрудника
        </DirectoriesAddButton>

        <MainInner>
          <Container>
            {response.data.data.employees.map((employee) => (
              <EmployeeCard
                selected={employee.id === selectedEmployee}
                onClick={() => setSelectedEmployee(employee.id)}
                key={employee.id}
                employee={employee}
                onDelete={() => {
                  setIsDeleteModalShown(true);
                  setEmployeeToDeleteId(employee.id);
                }}
                onEdit={() => {
                  setEmployeeToEdit(employee);
                  setIsEmployeeModalShown(true);
                }}
              />
            ))}
            {isDeleteModalShown && (
              <ConfirmDeleteModal
                isLoading={deleteMutation.isLoading}
                id={employeeToDeleteId}
                onDelete={handleDelete}
                onClose={() => setIsDeleteModalShown(false)}
                heading="сотрудника"
              />
            )}
            {isEmployeeModalShown && (
              <EmployeeModal
                onCreate={handleCreate}
                onEdit={handleEdit}
                isEdit={!!employeeToEdit}
                employee={employeeToEdit}
                isLoading={createMutation.isLoading || editMutation.isLoading}
                onClose={() => setIsEmployeeModalShown(false)}
              />
            )}
          </Container>
        </MainInner>
      </div>
    </>
  );
};
