import React, { useState } from "react";

import { Loader } from "../../Loaders";

import {
  CloseButton,
  Form,
  Input,
  Label,
  SubmitButton,
  Header,
  SelectElement,
  StyledModal,
} from "./styles";
import { CustomRadioButtons } from "../../CustomRadioButtons";
import { Textarea } from "../CreateModal/styles";

export const AttributeModal = ({
  handleEditAttribute,
  handleCreateAttribute,
  attribute,
  isEdit,
  isLoading,
  onClose,
}) => {
  const [name, setName] = useState(attribute?.name || "");
  const [type, setType] = useState(attribute?.type || {});
  const [comment, setComment] = useState(attribute?.comment || "");
  const [isMultiple, setIsMultiple] = useState(
    attribute?.multiple_values || false
  );
  const [isRequired, setIsRequired] = useState(attribute?.required || false);

  const options = [
    { label: "Строка", value: "Строка" },
    { label: "Число", value: "Число" },
    { label: "Булево", value: "Булево" },
    { label: "Справочник.Сотрудники", value: "Справочник.Сотрудники" },
    { label: "Справочник.Роли", value: "Справочник.Роли" },
    { label: "Справочник.Программы", value: "Справочник.Программы" },
    { label: "Справочник.Документы", value: "Справочник.Документы" },
    { label: "Справочник.Формы", value: "Справочник.Формы" },
    {
      label: "Справочник.ПроизвольныйСправочник",
      value: "Справочник.ПроизвольныйСправочник",
    },
  ];

  const buttonText = isEdit ? "Сохранить" : "Добавить";

  const handleTypeSelect = ({ value }) => {
    if (value === "Строка") {
      setType({
        app_type: "Строка",
        length: 500,
      });
    }
    if (value === "Число") {
      setType({
        app_type: "Число",
        length: 15,
        depth: 0,
      });
    }
    if (value === "Булево") {
      setType({
        app_type: "Булево",
      });
    }
    if (value.includes("Справочник")) {
      setType({
        app_type: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEdit) {
      handleEditAttribute({
        name,
        type,
        comment,
        multiple_values: isMultiple,
        required: isRequired,
      });
      return;
    }

    handleCreateAttribute({
      name,
      type,
      comment,
      multiple_values: isMultiple,
      required: isRequired,
    });
  };

  return (
    <StyledModal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />
      <Form onSubmit={handleSubmit}>
        <Header>
          {isEdit ? "Редактирование атрибута" : "Добавление атрибута"}
        </Header>

        <Label>
          Наименование
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <div style={{ marginBottom: "-16px" }}>
          <Label>
            Тип значения
            <SelectElement
              classNamePrefix="react-select"
              defaultValue={options.find(
                (option) => option.value === attribute?.type.app_type
              )}
              onChange={handleTypeSelect}
              options={options}
              placeholder={"Выберите..."}
            />
          </Label>
        </div>

        <CustomRadioButtons
          label="Множественное значение"
          name="multiple"
          disabled={type.app_type === "Булево"}
          defaultChecked={isMultiple}
          onChange={(e) => setIsMultiple(e.target.value)}
        />

        <CustomRadioButtons
          label="Сделать обязательным"
          name="required"
          defaultChecked={isRequired}
          onChange={(e) => setIsRequired(e.target.value)}
        />

        <Label style={{ marginTop: "14px" }}>
          Комментарий
          <Textarea
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>

        <SubmitButton disabled={isLoading} type="submit">
          {isLoading ? <Loader /> : buttonText}
        </SubmitButton>
      </Form>
    </StyledModal>
  );
};
