import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import GlobalStyles from "./Global";
import { Router } from "./components/Router";
import { AuthProvider } from "./hoc/AuthProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <GlobalStyles />
        <Router />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
