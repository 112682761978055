import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import {
  Form,
  CloseButton,
  Label,
  Header,
  Input,
  Textarea,
  SubmitButton,
  SelectElement,
} from "./styles";
import { Loader } from "../../Loaders";
import { baseUrl, getSubdomainName } from "../../../../api";
import { useAuth } from "../../../../hooks/useAuth";
import { Modal } from "../Modal";

export const EditModal = ({ isLoading, itemToEdit, onEdit, onClose }) => {
  const { token } = useAuth();
  const [name, setName] = useState(itemToEdit.name);
  const [mission, setMission] = useState(itemToEdit.mission);
  const [value, setValue] = useState(itemToEdit.value);
  const [result, setResult] = useState(itemToEdit.result);
  const [launchBase, setLaunchBase] = useState(itemToEdit.launchBase);
  const [parentId, setParentId] = useState(itemToEdit.parent_id || 0);
  const [comment, setComment] = useState(itemToEdit.comment);

  const { isLoading: isFoldersLoading, data: response } = useQuery(
    "getAllFolders",
    async () =>
      axios.get(`${baseUrl}/catalog/processes/?folders_tree=true`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      })
  );

  function getAllParents(array) {
    return array.reduce((acc, rec) => {
      if (rec.children.length) acc.push(...getAllParents(rec.children));
      acc.push({ value: rec.id, label: rec.name });
      return acc;
    }, []);
  }

  const options = response && getAllParents(response.data.data.processes);

  options?.push({ value: 0, label: "Нет" });

  const handleSubmit = (e) => {
    e.preventDefault();
    onEdit({
      name,
      parentId,
      comment,
      mission,
      value,
      result,
      launch_base: launchBase,
    });
  };

  return (
    <Modal>
      <CloseButton src="/closeModal.svg" onClick={onClose} />

      <Form onSubmit={(e) => handleSubmit(e)}>
        <Header>
          Редактировать {!itemToEdit.is_folder ? "процесс" : "группу"}
        </Header>
        <Label>
          Наименование
          <Input
            type="text"
            height={40}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Label>

        <div>
          {isFoldersLoading ? (
            "Loading..."
          ) : (
            <Label>
              Родительская группа
              <SelectElement
                classNamePrefix="react-select"
                defaultValue={
                  options.find((item) => item.value === itemToEdit.parent_id) ||
                  options[options.length - 1]
                }
                onChange={(i) => setParentId(i.value)}
                options={options}
              />
            </Label>
          )}
        </div>

        {!itemToEdit.is_folder && (
          <>
            <Label>
              Миссия
              <Input
                type="text"
                height={40}
                value={mission}
                onChange={(e) => setMission(e.target.value)}
              />
            </Label>
            <Label>
              Создаваемая ценность
              <Input
                type="text"
                height={40}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Label>
            <Label>
              Результат
              <Input
                type="text"
                height={40}
                value={result}
                onChange={(e) => setResult(e.target.value)}
              />
            </Label>
            <Label>
              Основание старта
              <Input
                type="text"
                height={40}
                value={launchBase}
                onChange={(e) => setLaunchBase(e.target.value)}
              />
            </Label>
          </>
        )}

        <div>
          <Label>
            Комментарий
            <Textarea
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Label>
        </div>

        <SubmitButton
          width={124}
          height={46}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <Loader /> : "Сохранить"}
        </SubmitButton>
      </Form>
    </Modal>
  );
};
