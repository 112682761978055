import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    
    box-sizing: border-box;
  }

  body {
    background: #fff;
    color: #203B54;
    font-family: "Noto sans", sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  ul {
    list-style-type: none;
  }
`;

export default GlobalStyles;
