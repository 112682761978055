import React from "react";

import {
  Container,
  EditButton,
  DeleteButton,
  Name,
  Number,
  HourlyCost,
} from "./styles";

export const RoleCard = ({
  role,
  index,
  onEdit,
  onDelete,
  onClick,
  isSelected,
}) => {
  const formatNumber = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <div>
        <Number>{index}.</Number>
        <Name>{role.name}</Name>
      </div>
      <div>
        <HourlyCost>
          <span>Стоимость часа</span>
          <span>{formatNumber(role.hourly_cost)} руб/час</span>
        </HourlyCost>
        <EditButton onClick={onEdit}>
          <img src="/editIcon.svg" alt="edit" />
          Редактировать
        </EditButton>
        <DeleteButton onClick={onDelete}>
          <img src="/trashBinIcon.svg" alt="" />
        </DeleteButton>
      </div>
    </Container>
  );
};
