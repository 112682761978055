import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import {
  Breadcrumbs,
  Commentary,
  Container,
  Details,
  MainInner,
  Main,
  Name,
} from "./styles";
import { AttributeCard } from "./AttributeCard";
import { Author, Avatar } from "../../pages/CatalogPage/styles";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl, getSubdomainName } from "../../api";
import { ConfirmDeleteModal } from "../UI/Modals/ConfirmDeleteModal";
import { ButtonBottom, ButtonTop, Utility } from "./AttributeCard/styles";
import { AttributeModal } from "../UI/Modals/AddAttributeModal";
import { DirectoriesAddButton } from "../UI/DirectoriesAddButton";
import { DirectoriesLoader } from "../../pages/DirectoryPage/styles";

export const StagesAttributes = () => {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  const [attributeToDeleteId, setAttributeToDeleteId] = useState(null);
  const [attributeToEdit, setAttributeToEdit] = useState(null);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isAttributeModalShown, setIsAttributeModalShown] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(false);

  const {
    isLoading,
    isError,
    error,
    data: response,
  } = useQuery("getAllAttributes", async () =>
    axios.get(`${baseUrl}/catalog/attributes`, {
      headers: {
        "business-space": `${getSubdomainName()}`,
        Authorization: `Bearer ${token}`,
      },
    })
  );

  const createMutation = useMutation(
    "addAttribute",
    async (data) => {
      await axios.post(`${baseUrl}/catalog/attributes`, data, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllAttributes");
        setIsAttributeModalShown(false);
      },
    }
  );

  const editMutation = useMutation(
    "editAttribute",
    async (data) => {
      await axios.put(
        `${baseUrl}/catalog/attributes/${attributeToEdit.id}`,
        data,
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsAttributeModalShown(false);
        queryClient.invalidateQueries("getAllAttributes");
      },
    }
  );

  const deleteMutation = useMutation(
    "deleteAttribute",
    async (id) => {
      await axios.delete(`${baseUrl}/catalog/attributes/${id}`, {
        headers: {
          "business-space": `${getSubdomainName()}`,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllAttributes");
      },
    }
  );

  const moveMutation = useMutation(
    "moveAttribute",
    async ({ id, direction }) => {
      await axios.post(
        `${baseUrl}/catalog/attributes/${id}/move`,
        {
          direction,
        },
        {
          headers: {
            "business-space": `${getSubdomainName()}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllAttributes");
        setIsAttributeModalShown(false);
      },
    }
  );

  const handleCreate = (data) => {
    createMutation.mutate(data);
  };

  const handleEdit = (data) => {
    editMutation.mutate(data);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
    setIsDeleteModalShown(false);
  };

  const handleMove = (id, direction) => {
    if (id === undefined) return;
    moveMutation.mutate({ id, direction });
  };

  if (isLoading) return <DirectoriesLoader />;
  if (isError) return <div>{error.message}</div>;

  return (
    <Container>
      <Breadcrumbs>
        <div>
          <span>Справочники</span>
          <img
            style={{ margin: "0 10px" }}
            src="/breadCrumbArrow.svg"
            alt="arrow"
          />
        </div>
        <div>
          <span>Атрибуты этапов</span>
        </div>
      </Breadcrumbs>

      <Main
        isItemSelected={!!selectedAttribute}
        onClick={() => setSelectedAttribute(false)}
      >
        <Utility>
          <ButtonTop
            onClick={(e) => {
              e.stopPropagation();
              handleMove(selectedAttribute?.id, "-1");
            }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="4.5"
                fill="white"
              />
              <path
                d="M9.04211 14.5118C9.0505 14.5964 9.09188 14.6749 9.15811 14.7319C9.22434 14.7889 9.31063 14.8203 9.40001 14.8199L12.4421 14.8199L12.4421 21.2799C12.4414 21.325 12.4502 21.3697 12.4679 21.4115C12.4856 21.4533 12.5118 21.4914 12.5451 21.5235C12.5784 21.5556 12.6181 21.5811 12.6619 21.5984C12.7056 21.6158 12.7526 21.6248 12.8 21.6248C12.8474 21.6248 12.8944 21.6158 12.9381 21.5984C12.9819 21.5811 13.0216 21.5556 13.0549 21.5235C13.0882 21.4914 13.1144 21.4533 13.1321 21.4115C13.1498 21.3697 13.1586 21.325 13.1579 21.2799L13.1579 14.4799C13.1579 14.3898 13.1202 14.3033 13.0531 14.2395C12.9859 14.1758 12.8949 14.14 12.8 14.1399L10.1773 14.1399L14.9474 8.88057L19.7174 14.1399L17.0947 14.1399C16.9998 14.14 16.9088 14.1758 16.8417 14.2395C16.7746 14.3033 16.7369 14.3898 16.7368 14.4799L16.7368 22.2999L12.8 22.2999C12.7526 22.2993 12.7055 22.3076 12.6615 22.3244C12.6175 22.3412 12.5774 22.3662 12.5436 22.3978C12.5099 22.4294 12.4831 22.4671 12.4647 22.5087C12.4464 22.5503 12.437 22.5949 12.437 22.6399C12.437 22.685 12.4464 22.7296 12.4647 22.7712C12.4831 22.8127 12.5099 22.8504 12.5436 22.8821C12.5774 22.9137 12.6175 22.9387 12.6615 22.9555C12.7055 22.9723 12.7526 22.9806 12.8 22.9799L17.0947 22.9799C17.1897 22.9799 17.2807 22.9441 17.3478 22.8804C17.4149 22.8166 17.4526 22.7301 17.4526 22.6399L17.4526 14.8199L20.4947 14.8199C20.5633 14.8196 20.6303 14.8006 20.6877 14.7652C20.7452 14.7298 20.7908 14.6795 20.8191 14.6202C20.8474 14.5609 20.8571 14.4951 20.8472 14.4307C20.8373 14.3663 20.8081 14.3059 20.7632 14.2568L15.2158 8.13682C15.1822 8.10065 15.1409 8.07168 15.0946 8.05183C15.0483 8.03199 14.9981 8.02173 14.9474 8.02173C14.8966 8.02173 14.8464 8.03199 14.8001 8.05183C14.7538 8.07168 14.7125 8.10065 14.679 8.13682L9.13159 14.2568C9.09921 14.2913 9.07458 14.3317 9.0592 14.3756C9.04382 14.4194 9.038 14.4658 9.04211 14.5118Z"
                fill="#203B54"
              />
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="4.5"
                stroke="#DBE0E9"
              />
            </svg>
          </ButtonTop>
          <ButtonBottom
            onClick={(e) => {
              e.stopPropagation();
              handleMove(selectedAttribute?.id, "1");
            }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="4.5"
                fill="white"
              />
              <path
                d="M21.77 16.4882C21.7611 16.4036 21.7172 16.3251 21.6468 16.2681C21.5765 16.2111 21.4849 16.1797 21.39 16.1801L18.16 16.1801L18.16 9.72005C18.1607 9.675 18.1514 9.63027 18.1326 9.58846C18.1139 9.54665 18.086 9.5086 18.0506 9.47651C18.0153 9.44443 17.9731 9.41895 17.9267 9.40156C17.8802 9.38417 17.8304 9.37521 17.78 9.37521C17.7296 9.37521 17.6798 9.38417 17.6333 9.40156C17.5869 9.41895 17.5447 9.44443 17.5094 9.47651C17.474 9.5086 17.4461 9.54665 17.4274 9.58846C17.4086 9.63027 17.3993 9.675 17.4 9.72005L17.4 16.5201C17.4 16.6102 17.4401 16.6967 17.5113 16.7605C17.5826 16.8242 17.6792 16.86 17.78 16.8601L20.5647 16.8601L15.5 22.1194L10.4353 16.8601L13.22 16.8601C13.3208 16.86 13.4174 16.8242 13.4887 16.7605C13.56 16.6967 13.6 16.6102 13.6 16.5201L13.6 8.70005L17.78 8.70005C17.8304 8.70069 17.8804 8.69237 17.9271 8.67557C17.9738 8.65877 18.0163 8.63382 18.0522 8.60219C18.0881 8.57055 18.1165 8.53286 18.136 8.49129C18.1554 8.44972 18.1654 8.40511 18.1654 8.36005C18.1654 8.31499 18.1554 8.27039 18.136 8.22882C18.1165 8.18725 18.0881 8.14955 18.0522 8.11792C18.0163 8.08628 17.9738 8.06134 17.9271 8.04454C17.8804 8.02774 17.8304 8.01942 17.78 8.02005L13.22 8.02005C13.1192 8.02006 13.0226 8.05589 12.9513 8.11965C12.8801 8.18341 12.84 8.26988 12.84 8.36005L12.84 16.1801L9.61 16.1801C9.53725 16.1804 9.46613 16.1994 9.40508 16.2348C9.34403 16.2702 9.29562 16.3205 9.26561 16.3798C9.2356 16.4391 9.22524 16.5049 9.23577 16.5693C9.2463 16.6337 9.27727 16.6941 9.325 16.7432L15.215 22.8632C15.2507 22.8994 15.2945 22.9283 15.3437 22.9482C15.3928 22.968 15.4461 22.9783 15.5 22.9783C15.5539 22.9783 15.6072 22.968 15.6563 22.9482C15.7055 22.9283 15.7493 22.8994 15.785 22.8632L21.675 16.7432C21.7094 16.7087 21.7355 16.6683 21.7519 16.6244C21.7682 16.5806 21.7744 16.5342 21.77 16.4882V16.4882Z"
                // fill="#203B54"
              />
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="4.5"
                // stroke="#DBE0E9"
              />
            </svg>
          </ButtonBottom>
          <DirectoriesAddButton
            onClick={() => {
              setAttributeToEdit(null);
              setIsAttributeModalShown(true);
            }}
          >
            атрибут
          </DirectoriesAddButton>
        </Utility>
        <MainInner isItemSelected={!!selectedAttribute}>
          {response?.data?.data?.attributes.map((attribute, index) => (
            <AttributeCard
              isSelected={selectedAttribute?.id === attribute.id}
              key={attribute.id}
              attribute={attribute}
              index={index + 1}
              onEdit={() => {
                setAttributeToEdit(attribute);
                setIsAttributeModalShown(true);
              }}
              onDelete={() => {
                setAttributeToDeleteId(attribute.id);
                setIsDeleteModalShown(true);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAttribute(attribute);
              }}
            />
          ))}
        </MainInner>
      </Main>

      {selectedAttribute && (
        <Details>
          <div>
            <Name>
              <span>Наименование программы</span>
              <p>{selectedAttribute ? selectedAttribute.name : " "}</p>
            </Name>

            <Author>
              <span>Автор</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>
                  {selectedAttribute ? selectedAttribute?.author?.name : " "}
                </p>
                {selectedAttribute?.author?.photo && (
                  <Avatar src={selectedAttribute?.author?.photo} alt="pic" />
                )}
              </div>
            </Author>
          </div>

          <Commentary>
            <span>Комментарий</span>
            <p>{selectedAttribute ? selectedAttribute.comment : " "}</p>
          </Commentary>
        </Details>
      )}

      {isAttributeModalShown && (
        <AttributeModal
          handleCreateAttribute={handleCreate}
          handleEditAttribute={handleEdit}
          attribute={attributeToEdit}
          isEdit={!!attributeToEdit}
          onClose={() => setIsAttributeModalShown(false)}
        />
      )}

      {isDeleteModalShown && (
        <ConfirmDeleteModal
          isLoading={deleteMutation.isLoading}
          id={attributeToDeleteId}
          onDelete={handleDelete}
          onClose={() => setIsDeleteModalShown(false)}
          heading="атрибут"
        />
      )}
    </Container>
  );
};
