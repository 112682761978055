export const baseUrl = "https://api.backend.mantrixum.com/mx/hs";

export const getSubdomainName = () => {
  let space;
  const address = window.location.host;

  if (address.includes("localhost")) {
    space = "demo";
  } else {
    const indexOfDot = address.indexOf(".");
    space = address.slice(0, indexOfDot);
  }
  return space;
};
