import React from "react";
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from "./styles";

export const CustomCheckbox = ({
  label,
  className,
  handleCheckboxChange,
  checked,
  styles,
  ...props
}) => {
  return (
    <label>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
          <img src="/checkIcon.svg" alt="check" width="12px" height="10px" />
        </StyledCheckbox>
      </CheckboxContainer>
      <span>{label}</span>
    </label>
  );
};
